<template>
  <div>
    <b-button
      v-b-modal="'activate-raffle-request-modal'"
      variant="outline-success"
      size="sm"
      :disabled="buttonDisabled"
    >
      <font-awesome-icon :icon="['far', 'circle-play']" />
      <span class="pl-2">{{ buttonText }}</span>
    </b-button>

    <b-modal
      :id="'activate-raffle-request-' + modalId"
      title="Request for Activation"
      v-model="modalShow"
      @ok="handleOk"
      @hidden="reset"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
      :ok-disabled="reasonsWhyActivationCannotBeRequested && reasonsWhyActivationCannotBeRequested.size > 0"
    >
      <template #modal-ok> Request Activation </template>
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <b-form @submit.stop.prevent="onSubmit">
        <p>
          Submit this request for our support team to review your raffle. Your raffle will be activated immediately upon
          approval.
        </p>
        <p class="mt-4">You can expect a response within 24 business hours.</p>
        <Alert
          v-if="reasonsWhyActivationCannotBeRequested && reasonsWhyActivationCannotBeRequested.size > 0"
          variant="red"
          class="mt-4"
          id="reasons-why-activation-cannot-be-requested-alert"
        >
          <h1 class="text-xl font-semibold text-red-600">Cannot Request Activation</h1>
          <ul class="compact-list text-base font-semibold">
            <li v-for="[categoryName, categoryData] in reasonsWhyActivationCannotBeRequested" :key="categoryName">
              <b-link
                v-if="categoryData.hyperlink"
                :href="categoryData.hyperlink"
                target="_blank"
                rel="noopener"
                class="text-black"
              >
                {{ categoryName }}
                <i class="fa-solid fa-xs fa-up-right-from-square"></i>
              </b-link>
              <span v-else>
                {{ categoryName }}
              </span>
              <ul class="compact-dashed-list text-sm font-normal">
                <li v-for="[definitionName, definitionData] in categoryData.definitions" :key="definitionName">
                  <b-link
                    v-if="definitionData.hyperlink"
                    :href="definitionData.hyperlink"
                    target="_blank"
                    rel="noopener"
                    class="text-black"
                  >
                    {{ definitionData.description }}
                    <i class="fa-solid fa-xs fa-up-right-from-square"></i>
                  </b-link>
                  <span v-else>
                    {{ definitionData.description }}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </Alert>
        <Alert v-if="errorMessage" variant="red">{{ errorMessage }}</Alert>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    Alert
  },
  props: {
    modalId: {
      type: String
    },
    event: {
      type: Object,
      default: () => {
        return {
          id: null
        };
      }
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: '',
      id: null,
      loading: false,
      activationRequested: false,
      activationRequirementDictionary: new Map([
        [
          'Incomplete event information',
          {
            hyperlink: '',
            definitions: new Map([
              [
                'license-missing',
                {
                  description: 'License is missing',
                  hyperlink: 'https://help.rafflebox.ca/how-to-upload-your-licence-to-your'
                }
              ],
              [
                'ticket-packages-missing',
                {
                  description: 'Ticket packages are missing'
                }
              ]
            ])
          }
        ],
        [
          'Incomplete billing information',
          {
            hyperlink: 'https://help.rafflebox.ca/update-your-billing-profile',
            definitions: new Map([
              [
                'billing-first-name-missing',
                {
                  description: 'First name is missing'
                }
              ],
              [
                'billing-last-name-missing',
                {
                  description: 'Last name is missing'
                }
              ],
              [
                'billing-email-missing',
                {
                  description: 'Email is missing'
                }
              ],
              [
                'billing-phone-missing',
                {
                  description: 'Phone is missing'
                }
              ],
              [
                'billing-address-line-missing',
                {
                  description: 'Address line is missing'
                }
              ],
              [
                'billing-city-missing',
                {
                  description: 'City is missing'
                }
              ],
              [
                'billing-province-missing',
                {
                  description: 'Province is missing'
                }
              ],
              [
                'billing-state-missing',
                {
                  description: 'State is missing'
                }
              ],
              [
                'billing-postal-missing',
                {
                  description: 'Postal code is missing'
                }
              ],
              [
                'billing-zip-missing',
                {
                  description: 'Zip code is missing'
                }
              ]
            ])
          }
        ],
        [
          'Incomplete Stripe information',
          {
            hyperlink: 'https://help.rafflebox.ca/stripe-connect-merchant-setup',
            definitions: new Map([
              [
                'stripe-missing',
                {
                  description: 'Stripe account is missing'
                }
              ],
              [
                'stripe-account-information-missing',
                {
                  description: 'Stripe account information is missing'
                }
              ],
              [
                'stripe-bank-account-missing',
                {
                  description: 'Bank account is missing'
                }
              ]
            ])
          }
        ]
      ])
    };
  },
  computed: {
    reasonsWhyActivationCannotBeRequested: function () {
      const result = new Map();

      this.event.activationRequirements?.forEach((requirement) => {
        for (const [category, value] of this.activationRequirementDictionary) {
          const requirementDefinitionFoundInThisCategory = value.definitions.get(requirement);

          if (requirementDefinitionFoundInThisCategory) {
            if (!result.has(category)) {
              result.set(category, {
                hyperlink: value.hyperlink,
                definitions: new Map()
              });
            }

            result.get(category).definitions.set(requirement, requirementDefinitionFoundInThisCategory);
          }
        }
      });

      return result;
    },
    isActivationRequested() {
      return this.event.activationRequested || this.activationRequested;
    },
    buttonText() {
      return this.isActivationRequested ? 'Pending Review' : 'Request Activation';
    },
    buttonDisabled() {
      return this.isActivationRequested;
    }
  },
  methods: {
    reset() {
      this.errorMessage = '';
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.errorMessage = '';
      this.loading = true;

      try {
        await EventService.requestRaffleActivation(this.event.id);

        this.activationRequested = true;
        this.modalShow = false;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.trumbowyg-box {
  margin-top: 0;
}
.compact-list {
  position: relative;
  padding-left: 1rem;
}
.compact-dashed-list {
  margin-top: 0.1rem;
  position: relative;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
.compact-dashed-list li:before {
  position: absolute;
  display: inline-block;
  content: '-';
  left: 0.25rem;
}
</style>
