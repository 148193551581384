<template>
  <div>
    <b-form-group
      label-for="input-starting-ticket-number"
      :invalid-feedback="veeErrors.first('input-starting-ticket-number')"
    >
      <template slot="label">Starting Ticket Number</template>
      <b-form-input
        name="input-starting-ticket-number"
        v-bind:value="value"
        v-on:input="onInput"
        v-validate="{ min_value: 0, integer: true }"
        type="number"
        :state="validateState()"
        aria-describedby="input-starting-ticket-number-feedback"
        data-vv-as="Starting Ticket Number"
        trim
        style="max-width: 15rem"
      />
    </b-form-group>
  </div>
</template>
<script>
import trumbowygConfig from '@/trumbowygConfig';

const name = 'input-starting-ticket-number';

export default {
  props: ['value'],
  data() {
    return {
      config: trumbowygConfig.getConfig()
    };
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      this.$validator.validate();
      return this.veeFields[name]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
