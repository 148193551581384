<template>
  <div>
    <b-form-group
      label-for="input-frequency"
      :label-size="labelSize"
      :invalid-feedback="veeErrors.first('input-frequency')"
    >
      <template slot="label">Frequency <span class="text-danger">*</span></template>
      <b-form-select
        v-model="selected"
        name="input-frequency"
        :state="validateState('input-frequency')"
        v-validate="'required'"
        v-bind:value="value"
        v-on:input="onInput"
        :size="inputSize"
        aria-describedby="input-frequency-feedback"
        :options="options"
        data-vv-as="frequency"
      />
    </b-form-group>
  </div>
</template>
<script>
import EventService from '@/lib/event-service-v2';

const name = 'input-frequency';

export default {
  props: ['value', 'labelSize', 'inputSize'],
  data() {
    return {
      event: this.$store.state.event,
      options: [],
      selected: ''
    };
  },
  async mounted() {
    this.options = [...(await EventService.listFrequencies())];
    if (this.event.frequency) {
      this.selected = this.event.frequency;
    } else {
      this.selected = this.options[0];
    }
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      return this.veeFields[name]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
