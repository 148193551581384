<template>
  <div class="drawer">
    <Transition
      name="drawer"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div v-if="drawerOpen" class="drawer-content">
        <slot></slot>
      </div>
    </Transition>
    <div v-if="controls" class="mt-4 toggle-wrapper border-b border-gray-300">
      <button class="toggle" @click.prevent="toggleDrawer">
        <span v-if="drawerOpen">
          Close
          <font-awesome-icon :icon="['far', 'chevron-up']" />
        </span>
        <span v-else>
          Open
          <font-awesome-icon :icon="['far', 'chevron-down']" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    controls: {
      type: Boolean,
      default: true
    },
    externalToggle: {
      type: Boolean,
      deafault: true
    },
    initial: {
      type: Boolean
    }
  },
  data() {
    return {
      drawerOpen: this.initial
    };
  },
  methods: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition(el) {
      el.style.height = '';
    }
  },
  watch: {
    externalToggle: function () {
      this.drawerOpen = !this.drawerOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer {
  &-content {
    background-color: white;
    overflow: hidden;
    transition: height 0.2s ease-out;
  }
}

.drawer-enter-active,
.drawer-leave-active {
  will-change: height;
  transition: height 0.2s ease-in-out;
}
.drawer-enter,
.drawer-leave-to {
  height: 0 !important;
}

.toggle {
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }

  &-wrapper {
    text-align: right;
  }
}
</style>
