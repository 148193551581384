<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div v-if="loading && !error" class="flex justify-center">
      <LoadingSpinner />
    </div>
    <div v-else class="mb-16">
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Raffle Sales<span>*</span></h1>
          <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
        </div>
      </div>
      <Alert v-if="!loading && error" id="error-alert" class="mt-2" icon="exclamation" variant="red">{{ error }}</Alert>
      <Alert v-if="!salesReport.eventId && !error" class="mt-2" icon="info" variant="yellow">
        No raffle sales have been recorded yet. Please check back later.
      </Alert>
      <div v-else class="max-w-full overflow-x-scroll">
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Raffle Sales
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="whitespace-nowrap p-3 text-left text-xs font-bold text-gray-900 w-auto">Online</th>
              <th class="whitespace-nowrap p-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Qty</th>
              <th class="whitespace-nowrap p-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <!-- Online -->
            <tr>
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Credit</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.onlineSales.credit.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.onlineSales.credit.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="salesReport.onlineSales.cash.quantity">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Cash</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.onlineSales.cash.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.onlineSales.cash.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="salesReport.onlineSales.cheque.quantity">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Cheque</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.onlineSales.cheque.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.onlineSales.cheque.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="salesReport.onlineSales.moneyOrder.quantity">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Money Order</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.onlineSales.moneyOrder.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.onlineSales.moneyOrder.totalCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-right"></td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ salesReport.onlineSales.totalQuantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ formatCurrency(salesReport.onlineSales.totalCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- InVenue -->
        <table class="min-w-full divide-y divide-gray-300 table-fixed" v-if="salesReport.eventId">
          <caption class="sr-only">
            In-Venue Sales
          </caption>
          <thead>
            <tr class="bg-gray-100" v-if="showInVenueSales">
              <th class="whitespace-nowrap p-3 text-left text-xs font-bold text-gray-900">InVenue</th>
              <th class="whitespace-nowrap p-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Qty</th>
              <th class="whitespace-nowrap p-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-if="showInVenueSales">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Credit</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.invenueSales.credit.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-80 text-right">
                {{ formatCurrency(salesReport.invenueSales.credit.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="showInVenueSales">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Debit</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.invenueSales.debit.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.invenueSales.debit.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="showInVenueSales">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Cash</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.invenueSales.cash.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.invenueSales.cash.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="showInVenueSales">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-right"></td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ salesReport.invenueSales.totalQuantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ formatCurrency(salesReport.invenueSales.totalCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Refunds -->
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            Refunds
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Refunds</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Qty</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr>
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Online</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.refunds.online.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.refunds.online.totalCents / 100) }}
              </td>
            </tr>
            <tr v-if="showInVenueSales">
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">InVenue</td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ salesReport.refunds.invenue.quantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.refunds.invenue.totalCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"></td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ salesReport.refunds.totalQuantity }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ formatCurrency(salesReport.refunds.totalCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Sales -->
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            Sales
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Sales</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Qty</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Total Ticket Sales
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ salesReport.totals.totalQuantity }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.totals.ticketSalesCents / 100) }}
              </td>
            </tr>
            <tr v-if="salesReport.totals.customerProcessingFeePercent">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Customer Processing Fee ({{ salesReport.totals.customerProcessingFeePercent }}%)
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.totals.customerProcessingFeeCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Total Sales</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ formatCurrency(salesReport.totals.totalCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Jackpot ({{ jackpotPercent }}%)
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-bold text-right">
                {{ formatCurrency(salesReport.totals.jackpotPercentCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Winners & Prizes -->
        <table
          class="min-w-full divide-y divide-gray-300"
          v-if="salesReport.eventId && salesReport.winnerPrizes.totalCents"
        >
          <caption class="sr-only">
            Winners &amp; Prizes
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Winners &amp; Prizes</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">50 / 50 Payout</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.winnerPrizes.totalCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Sales (After Winners &amp; Prizes Payouts)
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.winnerPrizes.netSalesCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Processing Fees -->
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            Processing Fees
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Processing Fees</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-if="salesReport.processingFees.stripeFeesCents > 0">
              <div class="d-flex align-items-center">
                <td class="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-6">
                  Stripe ({{ formatCurrency(salesReport.processingFees.stripeTxFeeCents / 100) }} +
                  {{ salesReport.processingFees.stripeTxFeePercent }}%) (Automatically Deducted)
                </td>
                <span
                  id="stripe-information"
                  class="stripe-information"
                  v-b-tooltip.hover.right="
                    'Credit card rates may vary depending on the card type. For example, Amex is higher.'
                  "
                >
                  <i class="fa-solid fa-circle-info fa-sm ml-2"></i>
                </span>
              </div>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.processingFees.stripeFeesCents / 100) }}
              </td>
            </tr>
            <tr v-if="salesReport.processingFees.payfactoFeesCents > 0">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                PayFacto ({{ formatCurrency(salesReport.processingFees.payfactoTxFeeCents / 100) }} +
                {{ salesReport.processingFees.payfactoTxFeePercent }}%) * Estimated (Automatically Deducted)
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.processingFees.payfactoFeesCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Total</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.processingFees.totalCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Platform Fees -->
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            Platform Fees
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Platform Fees</th>

              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-if="salesReport.platformFees.raffleboxFeeFlatRateCents > 0">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Rafflebox Fee</td>

              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.platformFees.raffleboxFeeFlatRateCents / 100) }}
              </td>
            </tr>
            <tr v-else>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Rafflebox Fee ({{ salesReport.platformFees.raffleboxFeePercent }}%) (Amount not final, invoice to
                follow)
              </td>

              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.platformFees.raffleboxFeeCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Other Fees</td>

              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.platformFees.otherFeesCents / 100) }}
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">Total</td>

              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.platformFees.totalCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Net Sales -->
        <table class="min-w-full divide-y divide-gray-300" v-if="salesReport.eventId">
          <caption class="sr-only">
            Net Sales
          </caption>
          <thead>
            <tr class="bg-gray-100">
              <th class="px-3 py-3 text-left text-xs font-bold text-gray-900">Total Net Sales</th>
              <th class="px-3 py-3 text-right text-xs font-bold text-gray-900 w-24 sm:w-36">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Net Sales (After all Fees &amp; Payouts) * This does not include Prizes or other expenses.
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(salesReport.netSalesCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="text-sm text-right"><span>*</span> This is not an invoice.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import { downloadFormattedCSV } from '@/lib/download-file';
import DownloadReportButton from '@/components/ui/DownloadReportButton';
import ReportServiceV2 from '@/lib/report-service-v2';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  components: {
    Alert,
    DownloadReportButton,
    LoadingSpinner
  },
  props: {
    eventId: {
      type: String,
      required: true
    },
    jackpotPercent: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      error: null,
      refreshingReport: false,
      loading: true,
      downloadingReport: false,
      salesReport: []
    };
  },
  async mounted() {
    await this.getReport();
  },
  computed: {
    showInVenueSales() {
      return this.$store.state.event.settings.allowInVenueSales;
    }
  },
  methods: {
    async getReport() {
      try {
        const response = await ReportServiceV2.retrieveEventSalesReport(this.eventId, {}, 'application/json');
        this.salesReport = response;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async refreshReport() {
      this.refreshingReport = true;
      await this.getReport();
      this.refreshingReport = false;
    },
    async downloadReport() {
      this.downloadingReport = true;
      try {
        const data = await ReportServiceV2.retrieveEventSalesReport(this.eventId, {}, 'text/csv');
        downloadFormattedCSV(`event_sales_${Date.now()}.csv`, data);
      } catch (error) {
        this.error = this.parseError(error).message;
      }
      this.downloadingReport = false;
    }
  }
};
</script>
