<template>
  <div>
    <b-form-row>
      <b-col cols="12" sm="12" md="12" lg="9" xl="9">
        <Alert v-if="errorMessage" variant="red" icon="exclamation" class="mr-5 my-3"
          >Error uploading image: {{ errorMessage }}</Alert
        >
        <ImageUploadCropper
          v-else
          :aspectRatio="{ aspectRatio: 16 / 9 }"
          :imageUrl="logoUrl"
          :uploadError="uploadError"
          :uploadSuccess="uploadSuccess"
          @uploadImage="uploadRaffleImage"
          @uploadImageError="uploadRaffleImageError"
          @clearImage="clearRaffleImage"
          imageName="raffleLogo"
        />
        <EventNameInput ref="eventNameInput" v-model="name" :disabled="isDisabled('name')" />
        <b-tabs>
          <b-tab title="About" class="p-2">
            <b-form-group
              label-for="input-description"
              :invalid-feedback="veeErrors.first('input-description')"
              class="mb-4"
            >
              <trumbowyg
                name="input-description"
                v-model="description"
                v-validate="{ min: 3 }"
                :state="validateState('input-description')"
                aria-describedby="input-description"
                data-vv-as="description"
                class="form-control"
                :disabled="isDisabled('description')"
                :config="config"
                @tbw-focus="toggleFocus"
              >
              </trumbowyg>
            </b-form-group>
          </b-tab>
          <b-tab title="Rules" class="p-2">
            <b-form-group label-for="input-rules-and-regs" :invalid-feedback="veeErrors.first('input-rules-and-regs')">
              <trumbowyg
                name="input-rules-and-regs"
                v-model="rules"
                v-validate="{ min: 3 }"
                :state="validateState('input-rules-and-regs')"
                aria-describedby="input-rules-and-regs"
                data-vv-as="rules and regulations"
                class="form-control"
                :disabled="isDisabled('rules')"
                :config="config"
                @tbw-focus="toggleFocus"
              >
              </trumbowyg>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="3" xl="3">
        <div>
          <b-form-group
            label="License Number"
            label-for="input-license-number"
            :invalid-feedback="veeErrors.first('input-license-number')"
            label-size="sm"
            label-class="mb-0"
            ref="licenseNumber"
          >
            <b-form-input
              name="input-license-number"
              v-model="licenseNumber"
              v-validate.bail="'min:5'"
              :state="validateState('input-license-number')"
              aria-describedby="input-license-number-feedback"
              data-vv-as="license number"
              size="sm"
              :disabled="isDisabled('licenseNumber')"
            />
          </b-form-group>
          <EventLicenseUploadInput
            v-if="this.basicForm.showLicenseUpload"
            :licenseNumber="licenseNumber"
            :organizationId="this.basic.organizationId"
            :licenseUrl="basic.licenseUrl"
            :smallField="true"
            :showDownloadLink="true"
            @setLicenseUrl="setLicenseUrl"
            :disabled="isDisabled('licenseUrl')"
          />
          <b-form-group
            label="Shortlink"
            label-for="input-shortlink"
            :invalid-feedback="veeErrors.first('input-shortlink')"
            description="The shortlink will be used for the custom section of your raffle url."
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-input
              name="input-shortlink"
              v-model="shortlink"
              v-validate="{ regex: /^[a-zA-Z0-9-]+$/ }"
              :state="validateState('input-shortlink')"
              aria-describedby="input-shortlink-feedback"
              data-vv-as="shortlink"
              size="sm"
              :disabled="isDisabled('shortlink')"
            />
          </b-form-group>
          <b-form-group
            :label="basic.dropdownLabel"
            :label-for="`input-${basic.dropdownLabel}`"
            description="Changing the province will affect the raffle end date/time."
            :invalid-feedback="veeErrors.first(`input-${basic.dropdownLabel}`)"
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-select
              :name="`input-${basic.dropdownLabel}`"
              v-model="province"
              :state="validateState(`${basic.dropdownLabel}_input`)"
              :aria-describedby="`input-${basic.dropdownLabel}-feedback`"
              :options="basic.provinceOptions"
              :data-vv-as="basic.dropdownLabel"
              :disabled="isDisabled('province')"
              v-validate="{ required: true }"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="input-category"
            description="Changing the category will determine what's displayed on the raffle page"
            :invalid-feedback="veeErrors.first('input-category')"
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-select
              name="input-category"
              :state="validateState('input-category')"
              v-model="category"
              aria-describedby="input-category-feedback"
              :options="basic.categoryOptions"
              v-validate="{ required: true }"
              data-vv-as="category"
              size="sm"
              :disabled="isDisabled('category')"
            />
          </b-form-group>
          <SalesRepDropdownInput size="sm" v-model="salesRepId" required v-if="showSalesRepDropDown" />
          <b-form-group
            label="Ticket Sales Start"
            :invalid-feedback="veeErrors.first('input-start-time')"
            label-for="input-start-date"
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-datepicker
              name="input-start-date"
              v-model="startDate"
              :state="validateState('input-start-date')"
              v-validate="{}"
              aria-describedby="input-start-date-feedback"
              data-vv-as="start date"
              size="sm"
              :disabled="isDisabled('startDate')"
              @hidden="validateEventDates"
            />
          </b-form-group>
          <b-form-group label-for="input-start-time" label-size="sm" label-class="mb-0" :description="basic.timeZone">
            <b-form-timepicker
              name="input-start-time"
              v-model="startTime"
              v-validate="{}"
              :state="validateState('input-start-time')"
              aria-describedby="input-start-time-feedback"
              data-vv-as="start time"
              size="sm"
              show-seconds
              :disabled="isDisabled('startTime')"
              @hidden="validateEventDates"
            />
          </b-form-group>
          <b-form-group
            label="Ticket Sales End"
            label-for="input-end-date"
            :invalid-feedback="veeErrors.first('input-end-date')"
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-datepicker
              name="input-end-date"
              v-model="endDate"
              :min="minEndDate"
              :state="validateState('input-end-date')"
              v-validate="{}"
              aria-describedby="input-end-date-feedback"
              data-vv-as="end date"
              size="sm"
              :disabled="isDisabled('endDate')"
              @hidden="validateEventDates"
            />
          </b-form-group>

          <b-form-group
            label-for="input-end-time"
            :invalid-feedback="veeErrors.first('input-end-time')"
            label-size="sm"
            label-class="mb-0"
            :description="timeZone"
          >
            <b-form-timepicker
              name="input-end-time"
              v-model="endTime"
              v-validate="{}"
              :min="minEndDate"
              :state="validateState('input-end-time')"
              aria-describedby="input-end-time-feedback"
              data-vv-as="end time"
              size="sm"
              show-seconds
              :disabled="isDisabled('endTime')"
              @hidden="validateEventDates"
            />
          </b-form-group>

          <b-form-group
            label="Draw Date"
            label-for="input-draw-date"
            :invalid-feedback="veeErrors.first('input-draw-date')"
            label-size="sm"
            label-class="mb-0"
          >
            <b-form-datepicker
              name="input-draw-date"
              v-model="drawDate"
              :state="validateState('input-draw-date')"
              aria-describedby="input-draw-date-feedback"
              v-validate="{ required: true }"
              :min="minDrawDate"
              data-vv-as="draw date"
              size="sm"
              :disabled="isDisabled('drawDate')"
              @hidden="validateEventDates"
            />
          </b-form-group>

          <b-form-group
            label-for="input-draw-time"
            :invalid-feedback="veeErrors.first('input-draw-time')"
            label-size="sm"
            label-class="mb-0"
            :description="timeZone"
          >
            <b-form-timepicker
              name="input-draw-time"
              v-model="drawTime"
              v-validate="{}"
              :state="validateState('input-draw-time')"
              aria-describedby="input-draw-time-feedback"
              data-vv-as="draw time"
              size="sm"
              show-seconds
              :min="minDrawDate"
              :disabled="isDisabled('drawTime')"
              @hidden="validateEventDates"
            />
          </b-form-group>
          <EventFrequencySelect v-model="frequency" label-size="sm" input-size="sm" />
          <b-form-group
            label="Draw Location"
            :invalid-feedback="veeErrors.first('input-draw-location')"
            label-for="input-draw-location"
            label-size="sm"
            label-class="mb-0"
            ><template slot="label">Draw Location <span class="text-danger">*</span></template>
            <b-form-input
              name="input-draw-location"
              :state="validateState('input-draw-location')"
              v-model="drawLocation"
              aria-describedby="input-draw-location-feedback"
              v-validate="{ required: true }"
              data-vv-as="draw location"
              size="sm"
              :disabled="isDisabled('drawLocation')"
            />
          </b-form-group>
          <div class="flex">
            <Toggle
              class="mt-4"
              v-model="showLiveRaffles"
              :disabled="isDisabled('showLiveRaffles')"
              label="Show on Live Raffles?"
              variant="success"
              @change="showLiveRaffles = $event"
            />
          </div>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import EventNameInput from '@/components/forms/EventNameInput';
import ImageUploadCropper from '@/components/forms/ImageUploadCropper';
import FileUploadServiceV2 from '@/lib/file-upload-service-v2';
import Alert from '@/components/ui/Alert';
import EventLicenseUploadInput from '@/components/inputs/EventLicenseUploadInput';
import SalesRepDropdownInput from '@/components/inputs/SalesRepDropdownInput';
import { mapFields } from 'vuex-map-fields';
import EventFrequencySelect from '@/components/forms/EventFrequencySelect';
import { parseISO } from 'date-fns';
import trumbowygConfig from '@/trumbowygConfig';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Toggle from '@/components/rbComponents/Toggle.vue';

export default {
  components: {
    EventNameInput,
    ImageUploadCropper,
    EventLicenseUploadInput,
    SalesRepDropdownInput,
    EventFrequencySelect,
    Toggle,
    Alert
  },
  data() {
    return {
      config: trumbowygConfig.getConfig(),
      isRbAdmin: this.$store.state.isRbAdmin,
      isRaffleAdmin: this.$store.state.isRaffleAdmin,
      isAccountOwner: this.$store.state.isAccountOwner,
      noEnforceFocus: this.$store.getters.getNoEnforceFocus,
      minEndDate: this.$store.getters.getBasicForm.startDate,
      minDrawDate: this.$store.getters.getBasicForm.endDate || this.$store.getters.getBasicForm.startDate,
      showSalesRepDropDown: false,
      editRaffleRestricted: false,
      errorMessage: null
    };
  },
  created() {
    this.setShowLicenseUpload();
  },
  async mounted() {
    this.showSalesRepDropDown = await unleashFeatureEnabled(UnleashKeys.EditRaffleSalesRep);
    this.editRaffleRestricted = await unleashFeatureEnabled(UnleashKeys.EditRaffleRestricted);
  },
  computed: {
    ...mapFields([
      'basicForm.name',
      'basicForm.description',
      'basicForm.rules',
      'basicForm.licenseNumber',
      'basicForm.shortlink',
      'basicForm.province',
      'basicForm.category',
      'basicForm.salesRepId',
      'basicForm.startDate',
      'basicForm.startTime',
      'basicForm.endDate',
      'basicForm.endTime',
      'basicForm.drawDate',
      'basicForm.drawTime',
      'basicForm.timeZone',
      'basicForm.frequency',
      'basicForm.drawLocation',
      'basicForm.showLiveRaffles',
      'basicForm.logoUrl',
      'basicForm.uploadError',
      'basicForm.uploadSuccess'
    ]),
    basic() {
      return this.$store.getters.getBasicData;
    },
    basicForm() {
      return this.$store.getters.getBasicForm;
    },
    status() {
      return this.$store.getters.getStatus;
    }
  },
  watch: {
    licenseNumber: function () {
      this.setShowLicenseUpload();
    },
    shortlink(newVal) {
      this.shortlink = newVal.trim();
    }
  },
  methods: {
    canUploadLicense() {
      return this.isRbAdmin || this.isRaffleAdmin || this.isAccountOwner;
    },
    async setLicenseUrl(url) {
      await this.$store.dispatch('setLicenseUrl', { licenseUrl: url });
    },
    async setShowLicenseUpload() {
      const licenseNumber = this.basicForm.licenseNumber;
      if (licenseNumber && licenseNumber.length >= 5 && this.canUploadLicense()) {
        await this.$store.dispatch('setShowLicenseUpload', true);
      } else {
        await this.$store.dispatch('setShowLicenseUpload', false);
      }
    },
    toggleFocus() {
      if (!this.noEnforceFocus) {
        this.$store.dispatch('setNoEnforceFocus', true);
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startTicketNum',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    },
    async validateEventDates() {
      // TODO move this to a library
      const startDateTime =
        this.basicForm.startDate && this.basicForm.startTime
          ? parseISO(`${this.basicForm.startDate} ${this.basicForm.startTime}`)
          : null;
      const endDateTime = this.basicForm.endDate
        ? parseISO(`${this.basicForm.endDate} ${this.basicForm.endTime}`)
        : null;
      const drawDateTime = this.basicForm.drawDate
        ? parseISO(`${this.basicForm.drawDate} ${this.basicForm.drawTime}`)
        : null;

      this.minEndDate = startDateTime;
      this.minDrawDate = endDateTime || startDateTime;

      if (this.startDate && !this.startTime) {
        this.veeFields['input-start-time']['dirty'] = true;
        this.veeFields['input-start-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-start-time',
          msg: 'The start time is required'
        });
        this.$store.dispatch('setDatesValid', false);
      } else if (startDateTime && startDateTime >= endDateTime) {
        this.veeFields['input-end-date']['dirty'] = true;
        this.veeFields['input-end-time']['dirty'] = true;
        this.veeFields['input-end-date']['invalid'] = true;
        this.veeFields['input-end-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-end-date',
          msg: 'The end date cannot be earlier than the ticket sales start time'
        });
        this.veeErrors.add({
          field: 'input-end-time',
          msg: 'The end time cannot be earlier than the ticket sales start time'
        });
        this.$store.dispatch('setDatesValid', false);
      } else if (endDateTime > drawDateTime) {
        this.veeFields['input-draw-date']['dirty'] = true;
        this.veeFields['input-draw-time']['dirty'] = true;
        this.veeFields['input-draw-date']['invalid'] = true;
        this.veeFields['input-draw-time']['invalid'] = true;
        this.veeErrors.add({
          field: 'input-draw-date',
          msg: 'The draw date cannot be earlier than the ticket sales end time'
        });
        this.veeErrors.add({
          field: 'input-draw-time',
          msg: 'The draw time cannot be earlier than the ticket sales end time'
        });
        this.$store.dispatch('setDatesValid', false);
      } else {
        this.$validator.validate('input-start-date');
        this.$validator.validate('input-start-time');
        this.$validator.validate('input-end-date');
        this.$validator.validate('input-end-time');
        this.$validator.validate('input-draw-date');
        this.$validator.validate('input-draw-time');
        this.$store.dispatch('setDatesValid', true);
      }
    },
    uploadRaffleImageError(error) {
      this.raffleImageUploadError = error?.message;
    },
    async uploadRaffleImage(payload) {
      if (payload.blob && payload.blob.type !== 'image/jpeg' && payload.blob.type !== 'image/png') {
        this.errorMessage = 'Please upload a valid image file type (JPEG or PNG)';

        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);

        return;
      }

      const formData = new FormData();
      formData.append('image', payload.blob, payload.name);

      try {
        const url = await FileUploadServiceV2.uploadFile(formData);

        await this.$store.dispatch('setBasicForm', {
          ...this.basicForm,
          logoUrl: url,
          raffleImageUploadSuccess: true,
          raffleImageUploadError: null
        });
      } catch (error) {
        this.raffleImageUploadError = error?.message;
        this.editRaffleFailed = true;
        this.errorMessage = 'Could not upload image';
      }
    },
    async clearRaffleImage() {
      await this.$store.dispatch('setBasicForm', {
        ...this.basicForm,
        logoUrl: null,
        raffleImageUploadError: null,
        raffleImageUploadSuccess: null
      });
    }
  }
};
</script>
