<template>
  <div>
    <div class="flex justify-center" v-if="loading && !errorMessage">
      <LoadingSpinner />
    </div>
    <div v-if="!loading && errorMessage">
      <Alert variant="red" icon="exclamation">Error loading voided ticket report: {{ errorMessage }}</Alert>
    </div>

    <div class="flex justify-content items-center border-b-2 h-8">
      <h2 class="card-title">Voided Tickets</h2>
      <div class="text-center my-3 ml-2">
        <BaseButton
          id="download-button"
          class="mb-4"
          variant="secondary-outline"
          :loading="buttonLoading"
          icon="arrow-down-to-line"
          @click.native="downloadReport"
        ></BaseButton>
        <b-tooltip target="download-button" triggers="hover focus" placement="right"> Download CSV </b-tooltip>
      </div>
    </div>
    <GenericTable
      v-if="!loading && !errorMessage"
      :columns="columns"
      :data="data"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
      :pagination="pagination"
      class="mt-4"
    >
      <template #ticketNumber="{ row }">
        <p>{{ row.ticketNumber }}</p>
      </template>
      <template #viewOrder="{ row }">
        <a :href="raffleboxUrl + '/orders?search=' + row.orderUuid"><p>View Order</p></a>
      </template>
      <template #orderNumber="{ row }">
        <p>{{ row.orderNumber }}</p>
      </template>
      <template #name="{ row }">
        <p>{{ row.name }}</p>
      </template>
      <template #voidedAt="{ row }">
        <span
          v-b-tooltip.hover
          :title="
            formatDateTime(parseISO(row.voidedAt), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          "
          class="dashline whitespace-nowrap"
        >
          {{ formatDateTime(parseISO(row.voidedAt)) }}
        </span>
      </template>
      <template #deviceName="{ row }">
        <p>{{ row.deviceName }}</p>
      </template>
      <template #deviceId="{ row }">
        <p>{{ row.deviceId }}</p>
      </template>
      <template #deviceSerialNumber="{ row }">
        <p>{{ row.deviceSerialNumber }}</p>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import Alert from '@/components/ui/Alert.vue';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { format, parseISO } from 'date-fns';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import { downloadFormattedCSV } from '@/lib/download-file';
import config from '@/config';

import ReportServiceV2 from '@/lib/report-service-v2.js';

export default {
  components: {
    GenericTable,
    Alert,
    LoadingSpinner,
    BaseButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      raffleboxUrl: config.RAFFLEBOX_DASHBOARD_URL,
      errorMessage: null,
      loading: true,
      columns: [
        { name: 'ticketNumber', label: 'Ticket #', sortable: true },
        { name: 'viewOrder', label: 'View Order', sortable: true },
        { name: 'orderNumber', label: 'Order #', sortable: true },
        { name: 'name', label: 'Name', sortable: true },
        { name: 'voidedAt', label: 'Voided', sortable: true },
        { name: 'deviceName', label: 'Device', sortable: true },
        { name: 'deviceId', label: 'Device ID', sortable: true },
        { name: 'deviceSerialNumber', label: 'Device Serial Number', sortable: true }
      ],
      data: [],
      modalConfig: {
        showCloseModalButton: false
      },
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0,
        sortBy: 'ticketNumber',
        sortDir: 'desc'
      },
      buttonLoading: false
    };
  },
  async mounted() {
    await this.refreshVoidedData();
  },
  methods: {
    async refreshVoidedData() {
      this.loadData();
    },
    formatDate(date) {
      return format(parseISO(date), 'MMMM do, yyyy');
    },
    async loadData() {
      try {
        const params = {
          eventId: this.eventId,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortBy: this.pagination.sortBy,
          sortDir: this.pagination.sortDir.toLocaleUpperCase()
        };

        const data = await ReportServiceV2.retrieveVoidedTicketsReport(params);

        this.data = data.data;

        this.pagination.total = data.total;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
      this.loading = false;
    },
    async downloadReport() {
      this.buttonLoading = true;
      try {
        this.csvData = await ReportServiceV2.retrieveVoidedTicketsReport({
          contentType: 'text/csv',
          eventId: this.eventId,
          pageSize: this.pagination.total
        });

        downloadFormattedCSV(`Voided Tickets Report - ${format(new Date(), 'yyyy-MM-dd-ppp')}.csv`, this.csvData);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.buttonLoading = false;
      }
    },
    sort(sortBy, sortDir) {
      this.$emit('sorting', true);
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      this.refreshVoidedData();
    },
    updateCurrentPage(page) {
      this.pagination.page = page;
      this.refreshVoidedData();
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
