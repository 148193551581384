<template>
  <div>
    <b-form-group
      label="Email Ticket Header"
      label-for="input-email-ticket-header"
      :invalid-feedback="veeErrors.first('input-email-ticket-header')"
      class="mb-4"
    >
      <trumbowyg
        name="input-email-ticket-header"
        v-model="emailTicketBody"
        v-validate="{ min: 3 }"
        :state="validateState('input-email-ticket-header')"
        aria-describedby="input-email-ticket-header"
        data-vv-as="email ticket header"
        class="form-control"
        :disabled="isDisabled('email ticket header')"
        :config="config"
        @tbw-focus="toggleFocus"
      >
      </trumbowyg>
    </b-form-group>

    <b-form-group
      label="Email Ticket Footer"
      label-for="input-email-ticket-header"
      :invalid-feedback="veeErrors.first('input-email-ticket-header')"
      class="mb-4"
    >
      <trumbowyg
        name="input-email-ticket-footer"
        v-model="emailTicketFooter"
        v-validate="{ min: 3 }"
        :state="validateState('input-email-ticket-footer')"
        aria-describedby="input-email-ticket-footer"
        data-vv-as="email ticket footer"
        class="form-control"
        :disabled="isDisabled('email ticket footer')"
        :config="config"
        @tbw-focus="toggleFocus"
      >
      </trumbowyg>
    </b-form-group>
  </div>
</template>

<script>
import trumbowygConfig from '@/trumbowygConfig';
import { mapFields } from 'vuex-map-fields';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  data() {
    return {
      config: trumbowygConfig.getConfig(),
      noEnforceFocus: this.$store.getters.getNoEnforceFocus,
      editRaffleRestricted: false
    };
  },
  async mounted() {
    this.editRaffleRestricted = await unleashFeatureEnabled(UnleashKeys.EditRaffleRestricted);
  },
  computed: {
    ...mapFields(['emailForm.emailTicketBody', 'emailForm.emailTicketFooter']),
    status() {
      return this.$store.getters.getStatus;
    }
  },
  methods: {
    toggleFocus() {
      if (!this.noEnforceFocus) {
        this.$store.dispatch('setNoEnforceFocus', true);
      }
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startTicketNum',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    }
  }
};
</script>
