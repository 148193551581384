<template>
  <div
    class="
      flex flex-column
      p-5
      md:p-10
      mb-12
      mr-6
      w-1/2
      h-fit
      bg-white
      border border-neutral-100
      rounded-3xl
      shadow-md
      overflow-hidden
    "
    v-if="salesReport.eventId"
  >
    <h1 class="text-lg sm:text-2xl pb-0 font-medium font-header">Current Jackpot!</h1>
    <p class="text-lg sm:text-3xl xl:text-6xl pb-1 sm:pb-3.5 font-bold font-body">
      <span class="break-words">{{ formatCurrency(salesReport.totals.jackpotPercentCents / 100) }}</span>
    </p>
    <p class="text-sm sm:text-base">{{ formatDate(startDate) }} – {{ formatDate(endDate) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    eventId: String,
    startDate: String,
    endDate: String,
    jackpotPercent: Number
  },
  computed: {
    salesReport() {
      return this.$store.state.eventSalesReport.data;
    }
  },
  methods: {
    formatCurrency(amount) {
      let amountString = String(amount);
      if (amountString.includes('.00')) {
        amountString = amountString.replace('.00', '');
      }
      amountString = amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return '$' + amountString;
    },
    formatDate(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    }
  }
};
</script>
