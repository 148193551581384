<template>
  <div>
    <multiselect
      v-model="inputValue"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      @search-change="searchChanged"
      :internal-search="false"
      :loading="loading"
      :allow-empty="true"
      :placeholder="placeholderText"
      :name="inputName"
    >
      <template #noResult> No events found matching search. </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

import EventService from '@/lib/event-service-v2';
export default {
  components: { Multiselect },
  props: {
    inputName: {
      type: String
    },
    placeholderText: {
      type: String,
      default: 'Find a Raffle'
    },
    organizationId: {
      type: String
    },
    value: {
      type: Object
    },
    currentEvent: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      options: []
    };
  },
  async mounted() {
    await this.loadEvents();
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.loadEvents(query), 300);
    },

    async loadEvents(query) {
      this.loading = true;

      const params = {};

      if (query) {
        params.search = query;
      }

      if (this.organizationId) {
        params.organizationId = this.organizationId;
      }

      this.options = [];

      try {
        // The selected event might not be in the query response so we need to fetch it separately
        if (this.value && !params.search) {
          const event = await EventService.retrieveEvent(this.value.id);

          this.options.push(event);
        }

        const response = await EventService.listEvents(params);

        for (const event of response.data) {
          // Filter the current event out of the list so a raffle isn't able to be linked to itself
          if (event.name != this.currentEvent.name) {
            this.options.push(event);
          }
        }
      } catch (error) {
        this.$emit('error', error);
      }
      this.loading = false;
    },

    reset() {
      this.value = null;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
