<template>
  <div>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Configuration</h1>
      <ul class="mb-4">
        <li>
          <div class="flex">
            <Toggle
              v-model="hidePot"
              :state="validateState('hide-pot-button')"
              name="hide-pot-button"
              :disabled="isDisabled('settings.hidePot')"
              label="Hide Pot Total"
              variant="success"
              @change="hidePot = $event"
            />
            <sup
              v-b-tooltip.hover.top="'If enabled then the Raffle page will not display the Jackpot / Total Sales.'"
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="hideWinnersList"
              :state="validateState('input-hide-winners-list')"
              name="input-hide-winners-list"
              :disabled="isDisabled('hideWinnersList')"
              label="Hide Winners Tab on Raffle Page"
              variant="success"
              @change="hideWinnersList = $event"
            />
            <sup
              v-b-tooltip.hover.top="'If enabled then Raffle page will not display the Winners tab.'"
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="hidePrizeList"
              :state="validateState('input-hide-prizes-list')"
              name="input-hide-winners-list"
              :disabled="isDisabled('hidePrizesList')"
              label="Hide Prizes Tab on Raffle Page"
              variant="success"
              @change="hidePrizeList = $event"
            />
            <sup
              v-b-tooltip.hover.top="'If enabled then Raffle page will not display the Prizes tab.'"
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="allowSecondaryName"
              :state="validateState('input-hide-prizes-list')"
              name="input-hide-winners-list"
              :disabled="isDisabled('allowSecondaryName')"
              label="Allow Additional Name Field on Checkout"
              variant="success"
              @change="allowSecondaryName = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled the checkout will allow the Ticket Buyer to add additional names when they make a purchase.'
              "
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="hideCountDownTimer"
              :state="validateState('hide-count-down-timer-switch')"
              name="hide-count-down-timer-switch"
              :disabled="isDisabled('settings.hideCountDownTimer')"
              label="Hide Countdown Timer"
              variant="success"
              @change="hideCountDownTimer = $event"
            />
            <sup
              v-b-tooltip.hover.top="'This will hide the countdown timer on the website for a raffle.'"
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="hidePurchaseTab"
              :state="validateState('hide-purchase-tab')"
              name="hide-purchase-tab"
              :disabled="isDisabled('settings.hidePurchaseTab')"
              label="Hide Recent Purchases"
              variant="success"
              @change="hidePurchaseTab = $event"
            />
            <sup
              v-b-tooltip.hover.top="'If enabled the Raffle page will not display the Recent Purchases section.'"
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
        <li>
          <div class="flex" v-if="canDisableSales">
            <Toggle
              v-model="disableOnlineSales"
              :state="validateState('disable-online-sales')"
              name="disable-online-sales"
              :disabled="isDisabled('settings.disableOnlineSales')"
              label="Turn Off Online Sales"
              variant="success"
              @change="disableOnlineSales = $event"
            />
            <sup
              v-b-tooltip.hover.top="
                'If enabled, online raffle sales will be turned off, and the raffle page will not be present on the website.'
              "
              class="mt-2 ml-2"
            >
              <i class="fa-solid fa-circle-info"></i>
            </sup>
          </div>
        </li>
      </ul>

      <div class="d-md-flex align-items-start">
        <div class="sm-wrap">
          <b-form-group
            label="Max Total Sales"
            label-for="input-max-jaxpot"
            :invalid-feedback="veeErrors.first('input-max-jaxpot')"
            label-size="md"
            style="max-width: 15rem"
          >
            <b-input-group prepend="$" append=".00" class="mr-3">
              <b-form-input
                name="input-max-jaxpot"
                v-model="maxJackpot"
                type="number"
                :state="validateState('input-max-jaxpot')"
                v-validate="{}"
                aria-describedby="input-max-jaxpot-feedback"
                data-vv-as="max total sales"
                size="md"
                :disabled="isDisabled('maxJackpot')"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="d-md-flex align-items-start">
          <div class="sm-wrap">
            <StartingTicketNumberInput ref="startingTicketNumberInput" v-model="startingTicketNumber" />
          </div>
        </div>
      </div>
    </section>

    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Jackpot Messaging</h1>

      <b-form-checkbox
        v-model="customJackpotEnabled"
        :state="validateState('enable-custom-jackpot-message')"
        name="enable-custom-jackpot-message"
        :disabled="isDisabled('customJackpotMessage')"
        switch
      >
        <span class="pr-1">Enable custom jackpot message</span>
        <sup
          v-b-tooltip.hover.top="
            'If enabled, the Raffle page will display the information below in the sidebar JackpotCard.'
          "
        >
          <i class="fa-solid fa-circle-info"></i>
        </sup>
      </b-form-checkbox>

      <b-form-group
        label-for="input-statement"
        description="This has a larger display than the sub-title. Max character count of 80."
        :invalid-feedback="veeErrors.first('input-statement')"
      >
        <template slot="label"> Main Statement <span v-if="requiredMessage" class="text-danger">*</span> </template>
        <b-form-input
          class="input-lg-width"
          name="input-statement"
          v-model="jackpotStatement"
          :state="validateState('input-statement')"
          v-validate="{ required: requiredMessage, max: 80 }"
          data-vv-as="main statement"
          placeholder="ie. $90,000"
          :disabled="!requiredMessage"
        />
      </b-form-group>

      <b-form-group
        label="Sub-title"
        label-for="input-sub-title"
        description="Max character count of 80."
        :invalid-feedback="veeErrors.first('input-sub-title')"
      >
        <b-form-input
          class="input-lg-width"
          name="input-sub-title"
          v-model="jackpotSubTitle"
          :state="validateState('input-sub-title')"
          v-validate="{ max: 80 }"
          data-vv-as="jackpot sub-title"
          placeholder="ie. winner takes half"
          :disabled="!requiredMessage"
        />
      </b-form-group>
    </section>

    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Jackpot Configuration</h1>
      <div class="d-md-flex align-items-start">
        <div class="sm-wrap">
          <b-form-group
            label="Starting"
            label-for="input-starting-pot"
            :invalid-feedback="veeErrors.first('input-starting-pot')"
            label-size="md"
            style="max-width: 15rem"
          >
            <b-input-group prepend="$" append=".00" class="mr-3">
              <b-form-input
                v-model="startingPot"
                name="input-starting-pot"
                v-validate="{ min_value: 0, integer: true }"
                type="number"
                min="0"
                :state="validateState({ min_value: 0 })"
                aria-describedby="input-starting-pot-feedback"
                data-vv-as="Starting"
                trim
                style="max-width: 15rem"
                :disabled="isDisabled('startingPot')"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="sm-wrap">
          <b-form-group
            label="Minimum"
            label-for="input-jackpot-display"
            :invalid-feedback="veeErrors.first('input-jackpot-display')"
            label-size="md"
            style="max-width: 15rem"
          >
            <b-input-group prepend="$" append=".00" class="mr-3">
              <b-form-input
                name="input-jackpot-display"
                v-model="minimumJackpotDisplay"
                type="number"
                min="0"
                :state="validateState('input-jackpot-display')"
                v-validate="{ min_value: 0 }"
                aria-describedby="input-jackpot-display-feedback"
                data-vv-as="Minimum"
                size="md"
                :disabled="isDisabled('minimumJackpotCents')"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="sm-wrap">
          <b-form-group
            label-for="input-jackpot-percent"
            :invalid-feedback="veeErrors.first('input-jackpot-percent')"
            label-size="md"
            style="max-width: 15rem"
          >
            <template slot="label">
              Percent
              <sup
                v-b-tooltip.hover.top="
                  'Percentage of the Jackpot to be displayed to the Ticket Buyers With no decimal value.'
                "
              >
                <i class="fa-solid fa-circle-info"></i>
              </sup>
            </template>
            <b-input-group append="%">
              <b-form-input
                name="input-jackpot-percent"
                v-model="fractionalJackpotPercent"
                type="number"
                min="1"
                max="100"
                step="0.01"
                :state="validateState('input-jackpot-percent')"
                v-validate="{ min_value: 1, max_value: 100, decimal: 0 }"
                aria-describedby="input-jackpot-percent-feedback"
                data-vv-as="Percent"
                size="md"
                :disabled="isDisabled('fractionalJackpotPercent')"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </div>
      <ExampleCalculator :starting="startingPot" :minimum="minimumJackpotDisplay" :maximum="maxJackpot" />
    </section>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

import StartingTicketNumberInput from '@/components/forms/StartingTicketNumberInput';
import ExampleCalculator from '@/components/forms/ExampleCalculator';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Toggle from '@/components/rbComponents/Toggle.vue';

export default {
  components: {
    StartingTicketNumberInput,
    ExampleCalculator,
    Toggle
  },
  data() {
    return {
      editRaffleRestricted: false
    };
  },
  computed: {
    ...mapFields([
      'settings.hidePot',
      'settings.hideWinnersList',
      'settings.hidePrizeList',
      'settings.allowSecondaryName',
      'settings.hideCountDownTimer',
      'settings.fractionalJackpotPercent',
      'settings.hidePurchaseTab',
      'settings.disableOnlineSales',
      'advancedForm.maxJackpot',
      'advancedForm.startingPot',
      'advancedForm.minimumJackpotDisplay',
      'advancedForm.startingTicketNumber',
      'settings.customJackpotEnabled',
      'settings.jackpotStatement',
      'settings.jackpotSubTitle'
    ]),
    settings() {
      return this.$store.getters.getSettings;
    },
    advancedForm() {
      return this.$store.getters.getAdvancedForm;
    },
    status() {
      return this.$store.getters.getStatus;
    },
    requiredMessage() {
      return this.settings.customJackpotEnabled;
    },
    canDisableSales() {
      return this.$store.state.isRbAdmin || this.$store.state.isRaffleAdmin || this.$store.state.isAccountOwner;
    }
  },
  async mounted() {
    this.editRaffleRestricted = await unleashFeatureEnabled(UnleashKeys.EditRaffleRestricted);
  },
  watch: {
    customJackpotEnabled: function () {
      if (!this.requiredMessage) {
        this.$store.dispatch('setSettings', {
          ...this.settings,
          jackpotStatement: '',
          jackpotSubTitle: ''
        });
      }
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startingTicketNumber',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    }
  }
};
</script>
