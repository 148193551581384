var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading && !_vm.errorMessage)?_c('div',{staticClass:"flex justify-center"},[_c('LoadingSpinner')],1):_vm._e(),(!_vm.loading && _vm.errorMessage)?_c('div',[_c('Alert',{attrs:{"variant":"red","icon":"exclamation"}},[_vm._v("Error loading voided ticket report: "+_vm._s(_vm.errorMessage))])],1):_vm._e(),_c('div',{staticClass:"flex justify-content items-center border-b-2 h-8"},[_c('h2',{staticClass:"card-title"},[_vm._v("Voided Tickets")]),_c('div',{staticClass:"text-center my-3 ml-2"},[_c('BaseButton',{staticClass:"mb-4",attrs:{"id":"download-button","variant":"secondary-outline","loading":_vm.buttonLoading,"icon":"arrow-down-to-line"},nativeOn:{"click":function($event){return _vm.downloadReport($event)}}}),_c('b-tooltip',{attrs:{"target":"download-button","triggers":"hover focus","placement":"right"}},[_vm._v(" Download CSV ")])],1)]),(!_vm.loading && !_vm.errorMessage)?_c('GenericTable',{staticClass:"mt-4",attrs:{"columns":_vm.columns,"data":_vm.data,"pagination":_vm.pagination},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"ticketNumber",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.ticketNumber))])]}},{key:"viewOrder",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":_vm.raffleboxUrl + '/orders?search=' + row.orderUuid}},[_c('p',[_vm._v("View Order")])])]}},{key:"orderNumber",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.orderNumber))])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.name))])]}},{key:"voidedAt",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline whitespace-nowrap",attrs:{"title":_vm.formatDateTime(_vm.parseISO(row.voidedAt), {
            format: 'dateTime',
            timeZone: _vm.sessionUserTimeZone,
            displayTimeZone: true
          })}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.voidedAt)))+" ")])]}},{key:"deviceName",fn:function(ref){
          var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.deviceName))])]}},{key:"deviceId",fn:function(ref){
          var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.deviceId))])]}},{key:"deviceSerialNumber",fn:function(ref){
          var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.deviceSerialNumber))])]}}],null,false,601851404)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }