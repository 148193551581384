<template>
  <multiselect
    v-model="selected"
    :options="options"
    label="name"
    track-by="id"
    :searchable="true"
    @search-change="searchChanged"
    :internal-search="false"
    :loading="loading"
    :allow-empty="true"
    :placeholder="placeholderText"
  >
    <template #noResult> No tags found matching search. </template>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import TagServiceV2 from '@/lib/tag-service-v2';

export default {
  components: { Multiselect },
  props: {
    system: {
      type: Boolean,
      default: true
    },
    placeholderText: {
      type: String,
      default: 'Find a tag'
    },
    organizationId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      options: [],
      selected: null
    };
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    }
  },
  async mounted() {
    this.loadTags();
  },
  methods: {
    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.loadTags(query), 300);
    },

    async loadTags(query) {
      this.loading = true;

      const params = {
        eventId: this.eventId,
        system: this.system
      };

      if (this.organizationId && !this.system) {
        params.organizationId = this.organizationId;
      }

      if (query) {
        params.search = query;
      }

      try {
        const response = await TagServiceV2.listTags(params);
        const options = [];

        for (const tag of response.data) {
          options.push({
            id: tag.id,
            name: tag.name
          });
        }

        this.options = options;
      } catch (error) {
        this.$emit('onError', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
