<template>
  <div :class="{ 'view-tags': tags.length }">
    <Alert variant="blue" icon="info" v-if="!tags || tags.length < 1" class="mb-0 mt-4 w-full max-w-xs">
      No tags found.
    </Alert>
    <tag
      v-for="tag in tags"
      :key="tag"
      :id="tag"
      colorClass="purple"
      variant="deletable"
      @onDeleteTag="onDeleteTag"
      class="mr-2 mb-2 mt-2"
    >
      {{ tag }}
    </tag>
  </div>
</template>

<script>
import Tag from '@/components/ui/Tag.vue';
import Alert from '@/components/ui/Alert';
import TagServiceV2 from '@/lib/tag-service-v2';

export default {
  props: ['organizationId', 'eventId', 'customerId', 'tags'],
  components: { tag: Tag, Alert },
  methods: {
    async onDeleteTag(name) {
      try {
        if (this.customerId) {
          await TagServiceV2.removeCustomerTag(this.organizationId, this.customerId, name);
        } else if (this.eventId) {
          await TagServiceV2.removeEventTag(this.organizationId, this.eventId, name);
        } else {
          await TagServiceV2.removeOrganizationTag(this.organizationId, name);
        }
        this.$emit('onDeleteTag', name);
      } catch (error) {
        this.$emit('onError', error);
      }
    }
  }
};
</script>

<style scoped>
.view-tags {
  padding: 0.5em;
  border: 1px dashed #cccccc;
  width: 100%;
  max-width: 20rem;
}

.badge {
  font-size: 14px;
  padding: 6px 8px;
}
</style>
