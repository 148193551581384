<template>
  <div class="rng-option">
    <b-form @submit.stop.prevent="generateRandomNumber">
      <b-form-group>
        <b-form-input placeholder="Start Number" v-model="rngStartNumber" type="number" class="col-4" />
      </b-form-group>
      <b-form-group>
        <b-form-input id="rng-end-number" placeholder="End Number" v-model="rngEndNumber" type="number" class="col-4" />
      </b-form-group>
      <b-button type="submit" cl variant="outline-secondary"> Generate Random Number </b-button>
      <div class="mt-4">
        <h4 v-if="rngRandomNumber" class="mt-2 chosen-number">
          Generated Number: <b-badge variant="success">{{ rngRandomNumber }}</b-badge>
        </h4>
      </div>
    </b-form>
  </div>
</template>

<script>
import RngService from '@/lib/rng-service';

export default {
  props: ['eventId'],
  data() {
    return {
      rngStartNumber: null,
      rngEndNumber: null,
      rngRandomNumber: null
    };
  },
  methods: {
    async generateRandomNumber() {
      if (this.rngStartNumber && this.rngEndNumber) {
        try {
          this.rngRandomNumber = await RngService.generateRandomNumber(
            this.rngStartNumber,
            this.rngEndNumber,
            this.eventId
          );
        } catch (error) {
          this.errorMessage = error;
          console.error(error);
        }
      }
    }
  }
};
</script>
