<template>
  <div :id="id" class="inline-flex flex-nowrap text-white text-sm tag">
    <div :class="`p-1 lowercase ${setColor} ${deleteClasses}`">
      <slot></slot>
    </div>
    <button
      aria-label="Delete tag"
      type="button"
      v-if="variant === 'deletable'"
      @click="deleteTag"
      :class="`border-l-2 border-black border-opacity-20 focus:outline-none focus:ring-2 ml-0 p-1 rounded-r-md ${setColor} ${setHoverColor} tag__delete`"
    >
      <span class="sr-only">Delete Tag</span>
      <font-awesome-icon :icon="['far', 'circle-xmark']" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    id: {
      type: String,
      required: true
    },
    colorClass: {
      type: String,
      default: 'gray',
      validator: (colorClass) => ['gray', 'red', 'green', 'blue', 'yellow', 'purple'].includes(colorClass)
    },
    variant: {
      type: String,
      default: 'default',
      validator: (variant) => ['default', 'deletable'].includes(variant)
    }
  },
  computed: {
    setColor() {
      /* Note: Tailwind classes must be the full string. No interpolation ie. `bg-${ colorVariable }-500` */
      switch (this.colorClass) {
        case 'red':
          return 'bg-red-600';
        case 'green':
          return 'bg-green-700';
        case 'blue':
          return 'bg-blue-600';
        case 'yellow':
          return 'text-black bg-yellow-600';
        case 'purple':
          return 'bg-purple-600';
        default:
          return 'bg-gray-600';
      }
    },
    setHoverColor() {
      /* Note: Tailwind classes must be the full string. No interpolation ie. `bg-${ colorVariable }-500` */
      switch (this.colorClass) {
        case 'red':
          return 'hover:bg-red-500 focus:ring-red-300';
        case 'green':
          return 'hover:bg-green-600 focus:ring-green-300';
        case 'blue':
          return 'hover:bg-blue-500 focus:ring-blue-300';
        case 'yellow':
          return 'bg-yellow-600 hover:bg-yellow-500 focus:ring-yellow-300';
        case 'purple':
          return 'hover:bg-purple-500 focus:ring-purple-300';
        default:
          return 'hover:bg-gray-500 focus:ring-gray-300';
      }
    },
    deleteClasses() {
      return this.variant === 'deletable' ? 'rounded-l-md' : 'rounded-md';
    }
  },
  methods: {
    deleteTag() {
      this.$emit('onDeleteTag', this.id);
    }
  }
};
</script>
