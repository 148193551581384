<template>
  <GenericModal
    :title="getTitle"
    size="sm"
    :config="modalConfig"
    @modal-closed="modalClosed"
    :errorMessage="errorMessage"
  >
    <template #openButton="{ openModal }">
      <div class="whitespace-nowrap text-right">
        <a class="text-gray-800 underline cursor-pointer" @click="openModal">
          {{ formatBalance(row.startingFloat / 100) }}
        </a>
      </div>
    </template>
    <template>
      <div class="p-2 text-left">
        <p class="mb-4 text-base">Add cash to this seller's float.</p>
        <ValidationObserver ref="observer">
          <b-form @submit.prevent="submit">
            <ValidationProvider
              :rules="{ min_value: 1, max_value: 999999, integer: true, required: true }"
              name="amount"
            >
              <b-form-group label-for="amount" class="mb-4 text-base" slot-scope="{ valid, errors }">
                <b-form-input
                  name="amount"
                  v-model="amount"
                  :state="errors[0] ? false : valid ? true : null"
                  type="number"
                  placeholder="0"
                  aria-describedby="amount-feedback"
                  data-vv-as="Cash Drop Amount"
                  trim
                  style="max-width: 15rem"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-form>
        </ValidationObserver>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <BaseButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </BaseButton>
      <LoadingButton variant="green" @click.native="submit(closeModal)">Save</LoadingButton>
    </template>
  </GenericModal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import GenericModal from '@/components/modals/GenericModal.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import DeviceServiceV2 from '@/lib/device-service-v2';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    GenericModal,
    LoadingButton,
    ValidationProvider,
    ValidationObserver,
    BaseButton
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalConfig: {
        showCloseModalButton: false
      },
      amount: null,
      title: 'Add to Float',
      errorMessage: null
    };
  },
  computed: {
    getTitle() {
      return `${this.title} - ${this.row.ticketSellerName || this.row.deviceName}`;
    }
  },
  methods: {
    formatBalance(balance) {
      if (balance < 0) {
        return `(${this.formatCurrency(balance * -1)})`;
      } else {
        return this.formatCurrency(balance);
      }
    },
    async submit(closeModal) {
      try {
        const isValid = await this.$refs.observer.validate();

        if (!isValid) {
          return;
        }

        await DeviceServiceV2.createCashDrop({
          deviceId: this.row.deviceId,
          eventId: this.eventId,
          amountCents: this.amount * 100,
          ticketSellerName: this.row.ticketSellerName
        });

        this.amount = null;
        closeModal();
        this.$emit('triggerRefresh');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    modalClosed() {
      this.amount = null;
      this.errorMessage = null;
      this.$refs.observer.reset();
    },
    closeModalHandler(closeModal) {
      closeModal();
    }
  }
};
</script>
