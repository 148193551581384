<template>
  <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700 flex" :class="heightClass">
    <transition appear @before-appear="animationStart" @after-appear="animationEnd">
      <div class="progress-bar rounded-full" :class="`${heightClass} ${variantClass}`"></div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      default: 100
    },
    height: {
      type: String,
      required: false,
      validator: (value) => ['small', 'medium', 'large'].includes(value)
    },
    variant: {
      type: String,
      required: false,
      validator: (value) => ['green', 'red', 'blue', 'yellow', 'purple'].includes(value)
    }
  },
  computed: {
    heightClass() {
      switch (this.height) {
        case 'small':
          return 'h-3';
        case 'medium':
          return 'h-5';
        case 'large':
          return 'h-10';
        default:
          return 'h-5';
      }
    },
    variantClass() {
      switch (this.variant) {
        case 'green':
          return 'bg-green-600';
        case 'red':
          return 'bg-red-600';
        case 'yellow':
          return 'bg-yellow-600';
        case 'purple':
          return 'bg-purple-600';
        case 'blue':
          return 'bg-blue-600';
        default:
          return 'bg-gray-500';
      }
    }
  },
  methods: {
    animationStart(el) {
      el.style.width = 0;
    },
    animationEnd(el) {
      let calculatedProgress;

      // If no total just use progress as percentage, if total then calculate percentage
      if (!this.total) {
        calculatedProgress = this.progress;
      } else if (this.progress > 100 && !this.total) {
        calculatedProgress = 100;
      } else {
        calculatedProgress = (this.progress / this.total) * 100;
      }

      el.style.width = `${calculatedProgress}%`;
      el.style.transition = 'width 1s linear';
    }
  }
};
</script>
