var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-content items-center border-b-2"},[_c('h2',{staticClass:"card-title pb-0 mb-0"},[_vm._v("Voided Orders")]),_c('div',{staticClass:"text-center -mb-1 ml-5"},[_c('BaseButton',{staticClass:"my-4",attrs:{"id":"download-button","variant":"secondary-outline","loading":_vm.buttonLoading,"icon":"arrow-down-to-line"},nativeOn:{"click":function($event){return _vm.downloadReport($event)}}}),_c('b-tooltip',{attrs:{"target":"download-button","triggers":"hover focus","placement":"right"}},[_vm._v(" Download CSV ")])],1)]),(!_vm.loading && _vm.errorMessage)?_c('div',[_c('Alert',{attrs:{"variant":"red","icon":"exclamation"}},[_vm._v("Error loading voided report: "+_vm._s(_vm.errorMessage))])],1):_vm._e(),(!_vm.errorMessage)?_c('GenericTable',{staticClass:"mt-8",attrs:{"columns":_vm.columns,"data":_vm.data,"loading":_vm.loading,"pagination":_vm.pagination},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.name))])]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.email))])]}},{key:"orderNumber",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.orderNumber))])]}},{key:"orderAmount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(row.orderAmount))+" ")]}},{key:"merchantFees",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(_vm.formatCurrency(row.merchantFees)))])]}},{key:"deviceName",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.deviceName))])]}},{key:"merchant",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.merchant))])]}},{key:"merchantReferenceId",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.merchantReferenceId))])]}},{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline whitespace-nowrap",attrs:{"title":_vm.formatDateTime(_vm.parseISO(row.createdAt), {
            format: 'dateTime',
            timeZone: _vm.sessionUserTimeZone,
            displayTimeZone: true
          })}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.createdAt)))+" ")])]}},{key:"voidedAt",fn:function(ref){
          var row = ref.row;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"dashline whitespace-nowrap",attrs:{"title":_vm.formatDateTime(_vm.parseISO(row.voidedAt), {
            format: 'dateTime',
            timeZone: _vm.sessionUserTimeZone,
            displayTimeZone: true
          })}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.voidedAt)))+" ")])]}}],null,false,3870096859)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }