<template>
  <b-modal
    id="edit-single-event-member-modal"
    title="Edit Event Member"
    v-model="modalShow"
    @show="onModalShow"
    @hidden="resetForm"
    @ok="handleOk"
    no-close-on-backdrop
    size="md"
    body-class="position-static"
  >
    <b-overlay :show="loading" no-wrap rounded="sm" />
    <b-form @submit.stop.prevent="onSubmit">
      <div v-if="errorMessage" class="alert alert-danger">Failed to edit in support of: {{ errorMessage }}</div>

      <b-form-group label="Name" label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
        <b-form-input
          name="input-name"
          v-model="name"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('input-name')"
          aria-describedby="input-name-feedback"
          data-vv-as="name"
          trim
        />
      </b-form-group>

      <b-form-group label="Email" label-for="input-email" :invalid-feedback="veeErrors.first('input-email')">
        <b-form-input
          name="input-email"
          v-model="email"
          v-validate="{ email: true }"
          :state="validateState('input-email')"
          data-vv-as="email"
          trim
        />
      </b-form-group>

      <b-form-group label="Number" label-for="input-number" :invalid-feedback="veeErrors.first('input-number')">
        <b-form-input
          name="input-number"
          type="number"
          v-model="number"
          v-validate="{ required: true, integer: true, min_value: 1 }"
          :state="validateState('input-number')"
          data-vv-as="number"
          trim
        />
      </b-form-group>

      <b-form-group label="Active?" label-for="input-active" :invalid-feedback="veeErrors.first('input-active')">
        <b-form-checkbox
          switch
          name="input-active"
          v-model="active"
          :state="validateState('input-active')"
          data-vv-as="active"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import EventService from '@/lib/event-service-v2';

export default {
  props: ['event', 'selectedEventMember'],
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      name: null,
      email: null,
      number: null,
      loading: false,
      active: null
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async onModalShow() {
      try {
        const eventMember = await EventService.retrieveEventMember(this.selectedEventMember);

        const { name, email, number, active } = eventMember;
        this.name = name;
        this.email = email;
        this.number = number;
        this.active = active;
      } catch (error) {
        // Handle any errors here
        this.errorMessage = 'Error fetching event member details.';
      }
    },
    resetForm() {
      this.name = null;
      this.email = null;
      this.number = null;
      this.active = null;

      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.loading = true;

      const updateEventMember = {
        name: this.name,
        email: this.email,
        number: this.number,
        active: this.active
      };

      try {
        await EventService.updateEventMember(this.selectedEventMember, updateEventMember);

        this.$emit('onEdited', updateEventMember);
        this.modalShow = false;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
