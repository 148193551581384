<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div v-if="loading && !error" class="flex justify-center">
      <LoadingSpinner />
    </div>
    <Alert v-else-if="!loading && error" id="error-alert" class="mt-2" icon="exclamation" variant="red">{{
      error
    }}</Alert>
    <Alert
      v-else-if="!(salesReport.items && salesReport.items.length)"
      id="no-device-sales-recorded-alert"
      class="mt-2"
      icon="info"
      variant="yellow"
      >No device sales have been recorded yet. Please check back later.
    </Alert>
    <div v-else>
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Top Sellers</h1>
        </div>
      </div>
      <div class="max-w-full overflow-x-scroll">
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Top Sellers Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-xs tracking-wide text-gray-900 w-44">
                Seller Name
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-44">
                Sales
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="device in salesReport.items" :key="device.deviceName">
              <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {{ device.topSellerName }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p :id="`sales-cents-${device.postgresDeviceUuid}`" class="dotted-underline">
                  {{ formatCurrency(device.salesCents / 100) }}
                </p>
                <b-tooltip :target="`sales-cents-${device.postgresDeviceUuid}`" triggers="hover" placement="lefttop">
                  <DeviceSalesReportTooltip
                    :quantity="device.numOrders"
                    :amount="formatCurrency(device.salesCents / 100)"
                    amountTitle="Amount Sold"
                    squareColorClass="bg-green-500"
                  />
                </b-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import DeviceSalesReportTooltip from '@/components/ui/DeviceSalesReportTooltip';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import ReportServiceV2 from '@/lib/report-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Alert,
    DeviceSalesReportTooltip,
    LoadingSpinner
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      salesReport: [],
      loading: true,
      error: null,
      downloadingReport: false,
      useV2Endpoint: false
    };
  },
  async mounted() {
    this.useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);
    await this.getReport();
  },
  methods: {
    async getReport() {
      try {
        const response = await ReportServiceV2.retrieveTopSellersReport(this.eventId, {}, 'application/json');
        this.salesReport = response;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
</style>
