<template>
  <div class="mb-16 prize-list">
    <Alert v-if="error" variant="red" icon="exclamation"> {{ error }}</Alert>
    <Alert v-else-if="showBatchPickWinnerAlert" variant="blue" icon="exclamation"
      >Winners are currently being picked</Alert
    >
    <Draggable
      v-if="!loadingData"
      :list="getPrizeList"
      :disabled="!enabled"
      :class="{ 'list-disabled': !enabled }"
      ghost-class="ghost"
      drag-class="dragging"
      animation="200"
      direction="vertical"
      handle=".prize-list-item--grip"
      @change="updateListOrder"
      emptyInsertThreshold="10"
    >
      <TransitionGroup type="transition">
        <PrizeListItem
          v-for="prize in getPrizeList"
          :key="prize.id"
          :event="event"
          :prize="prize"
          @downloadError="handleDownloadError"
          @complete="complete"
        />
      </TransitionGroup>
    </Draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import PrizeListItem from '@/components/prizes/PrizeListItem';
import Alert from '@/components/ui/Alert';
import { mapGetters } from 'vuex';

export default {
  components: {
    Draggable,
    PrizeListItem,
    Alert
  },
  props: {
    dragDropEnabled: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      enabled: this.dragDropEnabled,
      loadingData: true,
      error: null
    };
  },
  async created() {
    try {
      await this.$store.dispatch('listPrizes', this.event.id);
      await this.$store.dispatch('listPrizeWinners', { eventId: this.event.id });
      this.loadingData = false;
    } catch (error) {
      this.$emit('updateErrorMessage', error);
    }
  },
  computed: {
    showBatchPickWinnerAlert() {
      const prizes = this.getPrizeList;

      const prize = prizes.find((prize) => prize.picksPending > 0);

      return prize;
    },

    ...mapGetters(['getPrizeList'])
  },
  methods: {
    async updateListOrder() {
      // Block dragging and display spinner when updating
      this.enabled = false;
      this.$store.commit('TOGGLE_LOADING');

      // Create list of IDs in their new order and call the update function
      const updatedOrder = this.getPrizeList.map((prize) => prize.id);

      try {
        await this.$store.dispatch('updatePrizeOrder', { eventId: this.event.id, prizeOrder: updatedOrder });
      } catch (error) {
        this.$emit('updateErrorMessage', error);
      }

      // Re-enable dragging and remove spinner when update is finished
      this.$store.commit('TOGGLE_LOADING');
      this.enabled = true;
    },
    handleDownloadError(error) {
      this.error = error;
    },
    async complete() {
      await this.$store.dispatch('listPrizes', this.event.id);
      await this.$store.dispatch('listPrizeWinners', { eventId: this.event.id });
    }
  }
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.1;
}

.dragging {
  opacity: 1;
  background-color: #f3f4f6 !important;
}
</style>

<style lang="scss">
.list-disabled {
  .prize-list-item {
    opacity: 0.5;
    background-color: #f3f4f6 !important;

    .cursor-move {
      cursor: default !important;
    }
  }
}
</style>
