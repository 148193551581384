<template>
  <GenericTable
    :columns="columns"
    :pagination="pagination"
    :loading="loading"
    :data="data"
    :actions="true"
    :allowSelect="true"
    @sort="sort"
    @selectedRows="selectedRows"
    @updateCurrentPage="updateCurrentPage"
  >
    <template #eventMemberNumber="{ row }">
      {{ row.eventMemberNumber }}
    </template>
    <template #eventMemberName="{ row }">
      {{ row.eventMemberName }}
    </template>
    <template #name="{ row }">
      {{ row.name }}
    </template>
    <template #email="{ row }">
      {{ row.email }}
    </template>

    <template #active="{ row }">
      <span v-if="row.active"><Badge variant="success" label="Active" /></span>
      <span v-else-if="row.active === false"><Badge variant="warning" label="Inactive" /></span>
      <span v-else></span>
    </template>

    <template #numRefunds="{ row }">
      {{ row.numRefunds }}
    </template>
    <template #refundsCents="{ row }">
      {{ formatCurrency(row.refundsCents / 100) }}
    </template>
    <template #numOrders="{ row }">
      {{ row.numOrders }}
    </template>
    <template #salesCents="{ row }">
      {{ formatCurrency(row.salesCents / 100) }}
    </template>
  </GenericTable>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import Badge from '@/components/rbComponents/Badge.vue';

export default {
  components: {
    GenericTable,
    Badge
  },
  props: {
    data: {
      type: Array,
      required: false
    },
    sort: {
      type: Function,
      required: false
    },
    pagination: {
      type: Object,
      required: false
    },
    eventId: {
      type: String,
      required: true
    },
    updateCurrentPage: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'eventMemberNumber',
          label: '#',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'eventMemberName',
          label: 'Name',
          sortable: true,
          classes: 'w-auto'
        },

        {
          name: 'email',
          label: 'Email',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'active',
          label: 'Status',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'numRefunds',
          label: 'Qty Refunds',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'refundsCents',
          label: 'Refunds',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'numOrders',
          label: 'Qty Orders',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'salesCents',
          label: 'Total Sales',
          sortable: true,
          classes: 'w-auto'
        }
      ],
      title: 'Event Members List',
      selectedEventMembers: {}
    };
  },
  async beforeMount() {
    this.data = this.data || [];
  },
  methods: {
    selectedRows(rows) {
      this.selectedEventMembers = rows;
      this.$emit('updateSelectEventMember', rows);
    }
  }
};
</script>
