<template>
  <div class="additional-prizes">
    <div class="flex justify-between items-end mb-4 border-b border-gray-300 additional-prizes__heading">
      <div class="additional-prizes__titles">
        <h2 class="font-header text-2xl pb-0">Additional Prizes</h2>
        <div class="text-sm mb-2 sub-title">
          There must always be a grand prize. The prizes created below are additional to the main prize, and you will be
          required to choose a Grand Prize winner.
        </div>
      </div>

      <div class="flex items-start additional-prizes__buttons">
        <b-spinner class="mb-2" v-show="loading" variant="success" />
        <Button
          v-if="isSuperAdmin || allowCreatePrizes"
          variant="green"
          size="small"
          class="mb-2 ml-2 whitespace-nowrap"
          @onClick="create"
        >
          Create Prize
        </Button>
      </div>

      <CreateEditPrizeModal :event="event" />
    </div>
    <Alert v-if="additionalPrizesError" icon="exclamation" variant="red" class="w-6/12 mb-7 mt-5">
      {{ additionalPrizesError }}
    </Alert>
    <div class="additional-prizes__list">
      <Alert v-if="showBatchPickWinnerBadge" variant="purple" icon="info">
        <p>Winners are currently being picked</p>
      </Alert>

      <PrizeList
        :dragDropEnabled="true"
        :event="event"
        @updateErrorMessage="updateErrorMessage"
        @showBatchPickWinnerBadge="showBatchPickWinnerBadgeEvent"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/LoadingButton';
import CreateEditPrizeModal from '@/components/modals/CreateEditPrizeModal';
import PrizeList from '@/components/prizes/PrizeList';
import Alert from '@/components/ui/Alert';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Button,
    CreateEditPrizeModal,
    PrizeList,
    Alert
  },
  props: {
    allowCreatePrizes: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updateModal: false,
      additionalPrizesError: null,
      showBatchPickWinnerBadge: false,
      isSuperAdmin: false
    };
  },
  async mounted() {
    this.isSuperAdmin = unleashFeatureEnabled(UnleashKeys.SupportSuperAdmin);
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    create() {
      this.$root.$emit('bv::show::modal', 'create-prize');
    },
    updateErrorMessage(errorMessage) {
      this.additionalPrizesError = errorMessage;
    },
    showBatchPickWinnerBadgeEvent(value) {
      this.showBatchPickWinnerBadge = value;
    }
  }
};
</script>
