import Axios from '@/axios';
const contextRoot = '/event-service/v2';

export default {
  listTicketPackages: async (eventId) => {
    const response = await Axios.get(`${contextRoot}/ticket-packages?eventId=${eventId}`);

    return response.data.data;
  },
  createTicketPackage: async (body) => {
    const response = await Axios.post(`${contextRoot}/ticket-packages/`, body);

    return response.data.data.data;
  },
  updateTicketPackage: async (body, ticketPackageId) => {
    const response = await Axios.patch(`${contextRoot}/ticket-packages/${ticketPackageId}`, body);

    return response.data.data;
  },
  deleteTicketPackage: async (ticketPackageId) => {
    const response = await Axios.delete(`${contextRoot}/ticket-packages/${ticketPackageId}`);

    return response.data.data;
  }
};
