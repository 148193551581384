var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericTable',{attrs:{"columns":_vm.columns,"pagination":_vm.pagination,"loading":_vm.loading,"data":_vm.data,"actions":true,"allowSelect":true},on:{"sort":_vm.sort,"selectedRows":_vm.selectedRows,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"eventMemberNumber",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.eventMemberNumber)+" ")]}},{key:"eventMemberName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.eventMemberName)+" ")]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}},{key:"active",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('span',[_c('Badge',{attrs:{"variant":"success","label":"Active"}})],1):(row.active === false)?_c('span',[_c('Badge',{attrs:{"variant":"warning","label":"Inactive"}})],1):_c('span')]}},{key:"numRefunds",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numRefunds)+" ")]}},{key:"refundsCents",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(row.refundsCents / 100))+" ")]}},{key:"numOrders",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.numOrders)+" ")]}},{key:"salesCents",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(row.salesCents / 100))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }