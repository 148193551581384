<template>
  <div>
    <section class="rb-field-group">
      <h1 class="text-2xl font-medium">Member Selection Configuration</h1>
      <ul class="mb-4">
        <li>
          <div class="flex">
            <Toggle
              v-model="supportStatsActive"
              :disabled="isDisabled('supportStatsActive')"
              label="Display Drop Down"
              variant="success"
              @change="supportStatsActive = $event"
            />
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="supportStatsForceSelectionEnabled"
              :disabled="isDisabled('supportStatsForceSelectionEnabled')"
              label="Member Selection Required"
              variant="success"
              @change="supportStatsForceSelectionEnabled = $event"
            />
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="supportStatsShow"
              :disabled="isDisabled('supportStatsShow')"
              label="Display Top Fundraisers Tab"
              variant="success"
              @change="supportStatsShow = $event"
            />
          </div>
        </li>
        <li>
          <div class="flex">
            <Toggle
              v-model="hideEntireGroup"
              :disabled="isDisabled('settings.hideEntireGroup')"
              label="Hide Entire Group"
              variant="success"
              @change="hideEntireGroup = $event"
            />
          </div>
        </li>
      </ul>

      <b-form-group label="Support Stats Label" label-for="input-support-stats-label" label-class="pb-1">
        <b-form-input
          class="input-lg-width"
          name="input-support-stats-label"
          v-model="supportStatsLabel"
          :state="validateState('input-support-stats-label')"
          v-validate="{}"
          aria-describedby="input-support-stats-label"
          size="md"
          :disabled="isDisabled('supportStatsLabel')"
        />
      </b-form-group>
    </section>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Toggle from '@/components/rbComponents/Toggle.vue';

export default {
  components: {
    Toggle
  },
  data() {
    return {
      editRaffleRestricted: false
    };
  },
  async mounted() {
    this.editRaffleRestricted = await unleashFeatureEnabled('launchDarkly/subscribe', UnleashKeys.EditRaffleRestricted);
  },
  computed: {
    ...mapFields([
      'inSupportOfForm.supportStatsActive',
      'inSupportOfForm.supportStatsForceSelectionEnabled',
      'inSupportOfForm.supportStatsShow',
      'inSupportOfForm.supportStatsLabel',
      'settings.hideEntireGroup'
    ]),
    status() {
      return this.$store.getters.getStatus;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isDisabled(element) {
      if (this.editRaffleRestricted) {
        return false;
      }

      const disabledFields = [
        'drawType',
        'startDate',
        'startTime',
        'startTicketNum',
        'startingPot',
        'minimumJackpotCents',
        'maxJackpot',
        'fractionalJackpotPercent',
        'rules',
        'licenseNumber',
        'name',
        'province',
        'category',
        'drawLocation',
        'customJackpotMessage',
        'licenseUrl'
      ];

      if (this.status === 'pending') {
        return false;
      }

      if (['active', 'ended'].includes(this.status)) {
        return disabledFields.includes(element);
      }

      if (this.status === 'closed') {
        return element !== 'shortlink';
      }

      return true;
    }
  }
};
</script>
