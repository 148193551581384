<template>
  <div class="ml-8 mr-4 p-4 bg-gray-200 border-1 border-grey-200 rounded-b-lg prize-drawer">
    <div v-if="winner" class="xl:flex flex-row">
      <div class="w-7/12">
        <div class="mb-1 prize-drawer__name">
          <span class="font-header text-3xl">{{ winner.order.name }}</span>
        </div>
        <div v-if="winner.order.secondaryName" class="mb-4 prize-drawer__secondary-name">
          <span class="font-header text-lg"
            >Additional Names: <strong>{{ winner.order.secondaryName }}</strong></span
          >
        </div>
        <div class="mb-2 text-lg flex flex-row prize-drawer__ticket-order">
          <div class="pr-3">
            <span>
              Ticket #:
              <strong>{{ winner.ticket.fullTicketNumber }}</strong>
            </span>
          </div>
          <div class="pr-3">
            <span>
              Order #:
              <a :href="getHref">
                <strong>{{
                  formatUuid(`${this.drawType === 'rng' ? this.winner.order.id : this.winner.order.uuid}`)
                }}</strong>
              </a>
            </span>
          </div>
          <div v-if="this.deviceName" class="device-name">
            <span>
              Device Name:
              <strong>{{ this.deviceName }}</strong>
            </span>
          </div>
        </div>
      </div>
      <div class="w-5/12">
        <div class="flex justify-between items-start prize-drawer__contact-info">
          <a :href="`mailto:${winner.order.email}`">{{ winner.order.email }}</a>
          <a :href="`tel:${winner.order.phone}`">{{ winner.order.phone && formatPhone(winner.order.phone) }}</a>
        </div>
      </div>
    </div>

    <div class="xl:flex flex-row">
      <div class="w-8/12">
        <div v-if="cancelled.length || winnerConfirmed" class="prize-drawer__comments">
          <strong class="block">Comments:</strong>
          <ul class="pl-4 list-disc">
            <li v-for="comment in cancelled" :key="comment.id">
              <strong v-if="comment.status === 'cancelled'" class="text-red-500">Cancelled: </strong>
              <span>{{ comment.comment }}</span>
              <br />
              <span v-if="comment.status === 'cancelled'">
                Ticket #: <strong class="pr-2"> {{ comment.ticket.fullTicketNumber }} </strong>
              </span>
              <span v-if="comment.status === 'cancelled'">
                Order #:
                <a :href="'/orders?search=' + comment.order.id">
                  <strong>{{ formatUuid(comment.order.id) }}</strong>
                </a>
              </span>
            </li>
            <li v-if="winnerConfirmed && winner.comment">
              <strong class="text-green-600">Confirmed: </strong>{{ winner.comment }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="winnerConfirmed && winner.createdAt" class="w-4/12 self-start text-right">
        <strong>Confirmed: </strong> <DateTimeDisplay :date-time="winner.createdAt" :time-zone="timeZone" />
      </div>
    </div>
    <div class="w-full self-end text-right">
      <PrizeDrawConfirm :winner="winner" :winnerConfirmed="winnerConfirmed" @complete="complete" />
    </div>
  </div>
</template>

<script>
import DateTimeDisplay from '@/components/ui/DateTimeDisplay';
import PrizeDrawConfirm from '@/components/PrizeDrawConfirm';

export default {
  components: {
    DateTimeDisplay,
    PrizeDrawConfirm
  },
  props: {
    winner: {
      type: Object,
      required: true
    },
    cancelled: {
      type: Array
    },
    timeZone: {
      type: String,
      required: true
    },
    drawType: {
      type: String,
      required: true
    }
  },
  computed: {
    getHref() {
      return this.drawType === 'rng'
        ? `/orders?search=${this.winner.order.id}`
        : `/orders?search=${this.winner.order.uuid}`;
    },
    winnerConfirmed() {
      return this.winner.status === 'confirmed';
    },
    deviceName() {
      return this.$store.getters.winningOrder?.deviceName;
    }
  },
  methods: {
    complete(winner) {
      this.$bvModal.hide('confirm-winner-' + this.winner.id);
      this.$emit('complete', winner);
    }
  }
};
</script>

<style lang="scss" scoped>
.prize-drawer {
  &__name span {
    line-height: 1 !important;
  }

  a {
    color: #2c3e50;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
