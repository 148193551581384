<template>
  <div class="flex">
    <BaseButton v-b-modal.pick-winner-manual-modal variant="success" size="sm" :disabled="disabled">
      Pick Winner
    </BaseButton>

    <span
      id="winner-information"
      class="winner-information"
      v-b-tooltip.hover.right="'Winner will be picked manually using printed tickets and logged in the system.'"
    >
      <i class="fa-solid fa-circle-info"></i>
    </span>

    <!-- Step one: Enter ticket number -->
    <b-modal
      id="pick-winner-manual-modal"
      centered
      title="Manual Winner Selection"
      ok-title="Pick Winner"
      ok-variant="success"
      @show="resetModal"
      body-class="position-static"
      no-stacking
      no-fade
    >
      <b-overlay :show="pickingWinner" no-wrap rounded="sm" />
      <div class="pick-winner-container">
        <p class="my-3">
          After manually picking the winner, enter the number of the winning ticket in the field below to confirm the
          winner.
        </p>
        <label for="manual-winner">Ticket Number</label>
        <b-form-input
          id="manual-winner"
          placeholder="1234"
          v-model="manualWinningTicket"
          type="number"
          class="max-w-xs"
        ></b-form-input>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()"> Cancel </b-button>
        <b-button
          variant="success"
          :disabled="pickManualWinnerButtonDisabled()"
          v-b-modal.pick-winner-confirmation-modal
        >
          Next
        </b-button>
      </template>
    </b-modal>

    <!-- Step two: Confirm/Pick Winner -->
    <b-modal
      id="pick-winner-confirmation-modal"
      centered
      title="Manual Winner Selection"
      @hidden="resetModal"
      ok-title="Pick Winner"
      ok-variant="success"
      body-class="position-static"
      no-stacking
      no-fade
    >
      <b-overlay :show="pickingWinner" no-wrap rounded="sm" />
      <div class="px-6">
        <div v-if="eventV1.barrelDraw">
          <p class="mb-4 mt-2">
            You are about to select ticket number <strong>{{ manualWinningTicket }}</strong> as the winner for
            <strong>{{ event.name }}</strong
            >.
          </p>
          <p class="mb-7">Once a winner is selected you <strong>will not</strong> be able to modify this event.</p>
        </div>
        <p class="mt-2 mb-7" v-else>
          You are about to pick the winner for the raffle. Once a winner is selected you will not be able to modify this
          raffle.
        </p>
      </div>
      <!-- DO NOT REMOVE THIS - REQUIRED BY GLI -->
      <Alert icon="exclamation" variant="yellow" class="w-11/12 mt-5">
        By clicking Pick Winner you have confirmed that all sales for the Raffle have been reconciled.
      </Alert>
      <Alert v-if="pickWinnerError" icon="exclamation" variant="red" class="w-11/12 mt-5">
        {{ pickWinnerError }}
      </Alert>
      <template #modal-footer>
        <b-button variant="secondary" v-b-modal.pick-winner-manual-modal> Back </b-button>
        <b-button variant="success" @click="handleOk(event, $event)"> Pick Winner </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';
import EventService from '@/lib/event-service';

export default {
  name: 'PickWinnerManual',
  components: {
    Alert,
    BaseButton
  },
  props: {
    event: Object,
    eventV1: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manualWinningTicket: '',
      pickingWinner: false,
      pickWinnerError: null
    };
  },
  methods: {
    pickManualWinnerButtonDisabled() {
      return this.manualWinningTicket.length <= 0;
    },
    resetModal() {
      this.pickWinnerError = null;
      this.manualWinningTicket = '';
    },
    async handleOk(event, bvModalEvt) {
      bvModalEvt.preventDefault();
      this.pickingWinner = true;

      this.pickWinnerError = null;

      try {
        await EventService.closeEvent(event.id, this.eventV1.barrelDraw, this.manualWinningTicket);

        this.$emit('winnerPicked', 'V1');
        this.$root.$emit('bv::hide::modal', 'pick-winner-confirmation-modal');
      } catch (error) {
        this.pickWinnerError = 'There was an error picking the winner. Please try again.';
      } finally {
        this.pickingWinner = false;
      }
    }
  }
};
</script>

<style scoped>
.winner-information {
  padding-left: 0.5rem;
  vertical-align: top;
}
</style>
