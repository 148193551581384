<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div class="flex justify-center" v-if="loading && !error">
      <LoadingSpinner />
    </div>
    <div v-else>
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Ticket Sales</h1>
          <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
        </div>
      </div>
      <div v-if="!loading && error">
        <Alert v-if="error" variant="red" icon="exclamation">{{ error }}</Alert>
      </div>
      <div class="max-w-full overflow-x-scroll" v-else>
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Ticket Sales Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-xs tracking-wide text-gray-900 w-20">
                Series
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                # Tickets
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Price
              </th>
              <th scope="col" class="whitespace-nowrap px-6 py-3 text-right text-xs tracking-wide text-gray-900 w-1/5">
                Total Orders
              </th>
              <th scope="col" class="whitespace-nowrap px-6 py-3 text-right text-xs tracking-wide text-gray-900 w-1/5">
                Total Sales
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Total Tickets
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Limit
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Issued
              </th>
              <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
                Sold Out
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="item in salesReport" :key="item.series">
              <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {{ item.series }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.seriesTickets }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ formatCurrency(item.priceCents / 100) }}
              </td>
              <td class="whitespace-nowrap text-right px-6 py-4 text-sm text-gray-800">
                <div class="flex flex-row align-items-center justify-content-end gap-3">
                  <p>{{ item.numOrders }}</p>
                  <div class="w-44">
                    <ProgressBar
                      :progress="Number(item.numOrders)"
                      :total="Number(totals.orders)"
                      variant="green"
                      height="small"
                    />
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap text-right px-6 py-4 text-sm text-gray-800">
                <div class="flex flex-row align-items-center justify-content-end gap-3">
                  <p>{{ formatCurrency(item.salesCents / 100, { precision: 0 }) }}</p>
                  <div class="w-44">
                    <ProgressBar :progress="item.salesCents" :total="totals.sales" variant="green" height="small" />
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.numTicketsSold }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.limit }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                {{ item.issued }}
              </td>
              <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
                <p v-if="item.percentSoldOut > 0">{{ (Number(item.percentSoldOut) * 100).toFixed(2) }}%</p>
                <p v-else>-</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import ProgressBar from '@/components/ui/ProgressBar';
import ReportServiceV2 from '@/lib/report-service-v2';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import DownloadReportButton from '@/components/ui/DownloadReportButton';
import { downloadFormattedCSV } from '@/lib/download-file';

export default {
  components: {
    Alert,
    ProgressBar,
    LoadingSpinner,
    DownloadReportButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      error: null,
      salesReport: [],
      downloadingReport: false
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    totals() {
      const sales = this.salesReport.reduce((acc, item) => acc + item.salesCents, 0);
      const orders = this.salesReport.reduce((acc, item) => acc + Number(item.numOrders), 0);

      return {
        sales,
        orders
      };
    }
  },
  methods: {
    async loadData() {
      const params = {
        eventId: this.eventId
      };

      try {
        const response = await ReportServiceV2.retrieveTicketSalesReportV2(params);
        this.salesReport = response;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async downloadReport() {
      this.downloadingReport = true;
      const data = await ReportServiceV2.retrieveTicketSalesReportV2({
        eventId: this.eventId,
        contentType: 'text/csv'
      });
      downloadFormattedCSV(`ticket_sales_${Date.now()}.csv`, data);
      this.downloadingReport = false;
    }
  }
};
</script>
