<template>
  <div class="ml-8 mr-4 p-4 bg-gray-200 border-1 border-grey-200 rounded-b-lg prize-drawer">
    <div v-if="winnerSelected" class="xl:flex flex-row">
      <div class="w-7/12">
        <div class="mb-1 prize-drawer__name">
          <span class="font-header text-3xl">{{ winnerSelected.order.name }}</span>
        </div>
        <div class="mb-2 text-lg prize-drawer__ticket-order">
          <p class="inline-block">
            Ticket #: <strong class="pr-2">{{ winnerSelected.ticket.fullTicketNumber }}</strong>
          </p>
          <p class="inline-block">
            Order #:
            <a :href="'/orders?search=' + winnerSelected.order.id">
              <strong>{{ formatUuid(winnerSelected.order.id) }}</strong>
            </a>
          </p>
        </div>
        <div class="mb-1" v-if="winnerSelected.order.secondaryName">
          <p class="text-lg mb-0 mr-1 inline-block">Additional Names:</p>
          <p class="text-lg inline-block">
            {{ winnerSelected.order.secondaryName }}
          </p>
        </div>
      </div>
      <div class="w-5/12">
        <div class="flex flex-wrap justify-between items-start prize-drawer__contact-info">
          <a :href="`mailto:${winnerSelected.order.email}`">{{ winnerSelected.order.email }}</a>
          <a :href="`tel:${winnerSelected.order.phone}`">{{ formatPhone(winnerSelected.order.phone) }}</a>
        </div>
      </div>
      <b-modal
        :id="'unpick-winner-' + winner.id"
        :title="modalTitle"
        ok-variant="success"
        @ok="handleOk"
        no-close-on-backdrop
        size="m"
        body-class="position-static"
      >
        <p>This will set the winner status back to pending.</p>
        <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
      </b-modal>
    </div>

    <div v-if="commentList.length" class="xl:flex flex-row">
      <div class="w-8/12">
        <div class="prize-drawer__comments">
          <strong class="block">Comments:</strong>
          <ul class="pl-4 list-disc">
            <li v-for="comment in commentList" :key="comment.id">
              <strong v-if="comment.status === 'cancelled'" class="text-red-500">Cancelled: </strong>
              <strong v-if="comment.status === 'confirmed'" class="text-green-600">Confirmed: </strong>
              <span>{{ comment.comment }}</span>
              <br />
              <span v-if="comment.status === 'cancelled'">
                Ticket #: <strong class="pr-2"> {{ comment.ticket.fullTicketNumber }} </strong>
              </span>
              <span v-if="comment.status === 'cancelled'">
                Order #:
                <a :href="'/orders?search=' + comment.order.id">
                  <strong>{{ formatUuid(comment.order.id) }}</strong>
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="winnerConfirmed && winnerConfirmed.cutoffDate" class="w-4/12 self-start text-right">
        <strong>Draw Date: </strong> <DateTimeDisplay :date-time="winnerConfirmed.cutoffDate" :time-zone="timeZone" />
      </div>
    </div>
    <div class="w-full self-end text-right" v-if="winner && canUnpickWinner">
      <b-button variant="warning" size="sm" @click="handleModal('unpick')"> Unpick </b-button>
    </div>
  </div>
</template>

<script>
import DateTimeDisplay from '@/components/ui/DateTimeDisplay';
import WinnerService from '@/lib/winner-service-v2';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert';

export default {
  components: {
    DateTimeDisplay,
    Alert
  },
  props: {
    prize: {
      type: Object,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    },
    winners: {
      type: Array,
      require: true
    },
    winner: {
      type: [Object, Boolean],
      required: true
    }
  },
  data() {
    return {
      canUnpickWinner: false,
      errorMessage: '',
      busy: false,
      sessionUser: ''
    };
  },
  async mounted() {
    this.canUnpickWinner = await unleashFeatureEnabled(UnleashKeys.SupportSuperAdmin);
    this.sessionUser = this.$store.state.user;
  },
  methods: {
    handleModal() {
      this.$root.$emit('bv::show::modal', 'unpick-winner-' + this.winner.id);
    },
    async handleOk(event) {
      event.preventDefault();

      try {
        this.busy = true;
        if (!this.winner.id) {
          throw new Error('Winner not found.');
        }
        const winner = await WinnerService.updateWinner(this.winner.id, {
          status: 'pending',
          comment: 'Winner unpicked by ' + this.sessionUser.name + '.'
        });
        this.$bvModal.hide('unpick-winner-' + this.winner.id);
        this.$emit('complete', winner);
      } catch (error) {
        this.errorMessage = this.parseError(error);
      } finally {
        this.busy = false;
      }
    }
  },
  computed: {
    winnerConfirmed() {
      return this.winners.find((winner) => {
        return winner.status === 'confirmed';
      });
    },
    winnerSelected() {
      return this.winners.find((winner) => {
        return winner.status === 'pending' || winner.status === 'confirmed';
      });
    },
    commentList() {
      const commentList = this.winners.filter((selection) => {
        return selection.status === 'cancelled' || selection.status === 'confirmed';
      });

      return commentList.sort((first, second) => {
        return first.status.localeCompare(second.status);
      });
    },
    modalTitle() {
      return this.winnerSelected ? 'Unpick Winner' : 'No Winner Selected';
    }
  }
};
</script>

<style lang="scss" scoped>
.prize-drawer {
  a {
    color: #2c3e50;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
