import Axios from '@/axios';

const jobsUrl = '/job-service/v2/jobs';

const listJobs = async (params = {}) => {
  const response = await Axios.get(jobsUrl, {
    params
  });

  return response.data.data;
};

const createJob = async (body) => {
  const response = await Axios.post('/job-service/v2/jobs', body);

  return response.data.data.presignedUrl;
};

export default {
  listJobs,
  createJob
};
