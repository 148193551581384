<template>
  <div class="p-3">
    <div class="flex mb-3">
      <font-awesome-icon class="text-white text-lg mr-2 mt-px" :icon="['fas', 'hashtag']" />
      <div class="flex flex-col align-items-baseline">
        <p class="mb-1 text-base">Quantity</p>
        <p>{{ quantity }}</p>
      </div>
    </div>
    <div class="flex">
      <span class="h-4 w-4 mr-2 mt-1" :class="squareColorClass"></span>
      <div class="flex flex-col align-items-baseline">
        <p class="mb-1 text-base">{{ amountTitle }}</p>
        <p>{{ amount }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      required: true,
      type: Number
    },
    amount: {
      required: true,
      type: String
    },
    amountTitle: {
      required: true,
      type: String
    },
    squareColorClass: {
      type: String,
      default: 'bg-red-500'
    }
  }
};
</script>
