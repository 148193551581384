var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-full overflow-x-scroll mb-12 sm:mx-2 md:max-w-7xl"},[_c('h1',{staticClass:"text-2xl font-medium mb-0"},[_vm._v("Cash Drops")]),(!_vm.loading && _vm.errorMessage)?_c('div',[_c('Alert',{attrs:{"variant":"red","icon":"exclamation"}},[_vm._v("Error loading Cash Drops: "+_vm._s(_vm.errorMessage))])],1):_vm._e(),(!_vm.errorMessage)?_c('GenericTable',{staticClass:"cash-drop",attrs:{"columns":_vm.columns,"data":_vm.data,"loading":_vm.loading,"actions":true},scopedSlots:_vm._u([{key:"deviceName",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ path: ("device/" + (row.deviceId)) }}},[_c('span',{staticClass:"text-gray-900 underline"},[_vm._v(_vm._s(row.deviceName))])])]}},{key:"ticketSellerName",fn:function(ref){
var row = ref.row;
return [_c('SellerNameChangeModal',{attrs:{"row":row},on:{"triggerRefresh":_vm.refreshTable}})]}},{key:"latestDrop",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"dotted-underline",attrs:{"id":("last-drop-" + (row.deviceId))}},[_vm._v(" "+_vm._s(row.latestDrop ? _vm.format(new Date(row.latestDrop), 'yyyy-MM-dd') : '')+" ")]),(row.latestDrop)?_c('b-tooltip',{attrs:{"target":("last-drop-" + (row.deviceId)),"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.latestDrop), { format: 'dateTime', timeZone: _vm.sessionUserTimeZone, displayTimeZone: true }))+" ")]):_vm._e()]}},{key:"salesTotalCents",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatCurrency(row.salesTotalCents / 100)))])]}},{key:"startingFloat",fn:function(ref){
var row = ref.row;
return [(!_vm.isInteractionDisabled(row))?_c('CashDropAddToFloatModal',{attrs:{"row":row,"eventId":_vm.eventId},on:{"triggerRefresh":_vm.refreshTable}}):_c('div',[_vm._v(_vm._s(_vm.formatCurrency(row.startingFloat / 100)))])]}},{key:"balanceCents",fn:function(ref){
var row = ref.row;
return [_c('CashDropActivityModal',{attrs:{"row":row,"interactionsDisabled":_vm.isInteractionDisabled(row)},on:{"cash-drop-updated":_vm.markActivityModified,"cash-drop-deleted":_vm.markActivityModified,"modal-closed":_vm.refreshIfActivityModified}})]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('CashDropDropCashModal',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"row":row,"eventId":_vm.eventId,"disabled":_vm.isInteractionDisabled(row),"title":!row.ticketSellerName ? 'Seller name missing' : ''},on:{"triggerRefresh":_vm.refreshTable}})]}}],null,false,1674503214)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }