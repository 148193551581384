<template>
  <GenericModal :title="getTitle" size="sm" :config="modalConfig">
    <template #openButton="{ openModal }">
      <a href="#" class="text-gray-900 underline" @click="openModal">{{ row.ticketSellerName || 'None' }}</a>
    </template>
    <template>
      <div class="p-2">
        <p class="mb-4 text-base">New Seller Name</p>
        <b-form @submit.stop.prevent="submit">
          <b-form-group
            label-for="seller-name"
            :invalid-feedback="veeErrors.first('sellerName')"
            class="mb-4 text-base"
          >
            <b-form-input
              name="seller-name"
              v-model="sellerName"
              v-validate="{ required: true, max: 255 }"
              type="text"
              :state="validateState()"
              aria-describedby="seller-name-feedback"
              data-vv-as="Seller Name"
              trim
              style="max-width: 15rem"
            />
          </b-form-group>
        </b-form>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <LoadingButton variant="green" @click.native="submit(closeModal)">Save</LoadingButton>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import DeviceServiceV2 from '@/lib/device-service-v2';

export default {
  components: {
    GenericModal,
    LoadingButton
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalConfig: {
        showCloseModalButton: false
      },
      sellerName: this.row.ticketSellerName,
      title: 'Change Seller Name'
    };
  },
  computed: {
    getTitle() {
      return `${this.title} - ${this.row.deviceName}`;
    }
  },
  methods: {
    validateState() {
      if (
        this.veeFields['seller-name'] &&
        (this.veeFields['seller-name'].dirty || this.veeFields['seller-name'].validated)
      ) {
        return !this.veeErrors.has('seller-name');
      }

      return null;
    },
    isValid() {
      this.$validator.validate();
      return this.veeFields['seller-name']['invalid'] === false;
    },
    async submit(closeModal) {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          return;
        }

        await DeviceServiceV2.updateDevice(this.row.deviceId, {
          sellerName: this.sellerName
        });

        closeModal();
        this.$emit('triggerRefresh');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>
