<template>
  <div>
    <CurrentJackpot :eventId="eventId" :startDate="startDate" :endDate="endDate" :jackpotPercent="jackpotPercent" />
  </div>
</template>

<script>
import CurrentJackpot from '@/components/CurrentJackpot';

export default {
  components: {
    CurrentJackpot
  },
  props: {
    eventId: String,
    startDate: String,
    endDate: String,
    jackpotPercent: Number
  },
  async mounted() {
    this.loading = true;
    await this.getReport();
    this.loading = false;
  },
  methods: {
    async getReport() {
      try {
        await this.$store.dispatch('getEventSales', this.eventId);
      } catch (error) {
        this.errorMessage = `Failed to load Sales Report: ${error.message}`;
      }
    }
  }
};
</script>
