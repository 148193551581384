import Axios from '@/axios';

export default {
  generateRandomNumber: async (start, end, eventId) => {
    const body = {
      start,
      end,
      eventId
    };
    const response = await Axios.post(`/rng/number`, body);

    return response.data.data;
  }
};
