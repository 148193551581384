<template>
  <div>
    <b-form class="flex mb-4 w-full max-w-xs">
      <TagSelect ref="tagSelect" :system="system" />
      <b-button variant="success" @click="addTag" class="ml-3">Add</b-button>
    </b-form>
  </div>
</template>
<script>
import TagServiceV2 from '@/lib/tag-service-v2';
import TagSelect from '@/components/TagSelect';

export default {
  components: { TagSelect },
  props: {
    system: {
      type: Boolean,
      default: true
    },
    organizationId: {
      type: String
    },
    customerId: {
      type: String
    },
    eventId: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    async addTag() {
      try {
        let id;

        if (this.customerId) {
          id = await TagServiceV2.addCustomerTag(
            this.organizationId,
            this.customerId,
            this.$refs.tagSelect.selected.id
          );
        } else if (this.eventId) {
          id = await TagServiceV2.addEventTag(this.organizationId, this.eventId, this.$refs.tagSelect.selected.id);
        } else {
          id = await TagServiceV2.addOrganizationTag(this.organizationId, this.$refs.tagSelect.selected.id);
        }

        this.$refs.tagSelect.selected = null;

        this.$emit('onAddTag', id);
      } catch (error) {
        this.$emit('onError', error);
      }
    }
  }
};
</script>
<style scoped></style>
