<template>
  <GenericModal :title="title" size="sm" :config="modalConfig" @modal-closed="modalClosed">
    <template #openButton="{ openModal }">
      <button @click="openModal" class="p-2 focus:ring focus:ring-green-200 rounded-lg prize-list-item--delete">
        <font-awesome-icon class="text-xl" :icon="['far', 'trash-can']" />
      </button>
    </template>
    <template>
      <div v-if="loading" class="flex justify-center">
        <LoadingSpinner variant="success" />
      </div>
      <Alert v-else variant="yellow" icon="exclamation">
        You are about to delete the prize: <strong>{{ prize.name }}</strong>
      </Alert>
      <ErrorDisplay v-if="errorMessage" :error="errorMessage" />
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <BaseButton variant="secondary" @click.native="closeModal">Cancel</BaseButton>
      <BaseButton variant="danger" @click.native="submit(closeModal)" class="ml-2">Delete</BaseButton>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import Alert from '@/components/ui/Alert';
import ErrorDisplay from '@/components/ErrorDisplay';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    GenericModal,
    Alert,
    ErrorDisplay,
    LoadingSpinner,
    BaseButton
  },
  props: {
    prize: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      errorMessage: null,
      loading: false,
      comment: '',
      title: 'Delete Prize'
    };
  },
  methods: {
    async submit(closeModal) {
      this.loading = true;
      try {
        await this.$store.dispatch('deletePrize', this.prize.id);
        closeModal();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMessage = error;
      }
    },
    modalClosed() {
      this.errorMessage = null;
      this.$emit('close');
    }
  }
};
</script>
