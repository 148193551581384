<template>
  <section id="prizes-winners">
    <h1 class="text-4xl">Create Prizes and Pick Winners</h1>
    <hr />
    <CollapsibleDrawer v-if="false" :initial="true" class="mb-8">
      <!-- SLOT CONTENT -->
      <div class="xl:flex">
        <div class="xl:w-3/6 text-lg">
          <p class="mb-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque turpis ex, ullamcorper non diam quis,
            posuere blandit lorem. Mauris tincidunt quis ante luctus blandit. Duis nec condimentum tortor. Orci varius
            natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Phasellus id sem id nibh
            scelerisque tempor.
          </p>
          <ol class="mb-2 space-y-1 list-decimal">
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
            <li>Aliquam tincidunt mauris eu risus.</li>
            <li>Vestibulum auctor dapibus neque.</li>
            <li>Nunc dignissim risus id metus.</li>
          </ol>
        </div>
        <div class="xl:w-3/6 xl:ml-4">
          <ResponsiveYouTubeEmbed src="https://www.youtube.com/embed/z2gWoOkCS1g" />
        </div>
      </div>
    </CollapsibleDrawer>

    <GrandPrize class="mb-8" :event="event" />

    <AdditionalPrizes :event="event" :allowCreatePrizes="allowCreatePrizes" v-if="!isBarrelDraw" />
  </section>
</template>

<script>
import AdditionalPrizes from '@/components/prizes/AdditionalPrizes';
import CollapsibleDrawer from '@/components/ui/CollapsibleDrawer';
import GrandPrize from '@/components/prizes/GrandPrize';
import ResponsiveYouTubeEmbed from '@/components/ui/ResponsiveYouTubeEmbed';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    AdditionalPrizes,
    CollapsibleDrawer,
    GrandPrize,
    ResponsiveYouTubeEmbed
  },
  data() {
    return {
      event: null,
      eventV1: null,
      isBarrelDraw: true,
      allowCreatePrizes: false,
      showPrizeControls: false
    };
  },
  async beforeMount() {
    // Get V2 Event
    this.event = this.$store.state.event;

    // Set conditionals
    this.isBarrelDraw = this.event.drawType === 'barrel';
  },
  async mounted() {
    this.showPrizeControls = await unleashFeatureEnabled(UnleashKeys.PrizeDrawsPrizeControls);
    this.allowCreatePrizes = !['closed', 'ended'].includes(this.event.status) && this.showPrizeControls;
  }
};
</script>
