<template>
  <div v-if="event.status !== 'closed'" class="close-raffle-button">
    <div class="flex justify-center items-start">
      <Button
        :loading="loading"
        class="close-raffle-button__close"
        variant="red"
        size="large"
        :disabled="disableCloseButton"
        v-b-modal.close-raffle-modal
      >
        Close Raffle
      </Button>
      <span
        v-b-tooltip.hover.top="
          'By clicking Close Raffle you have confirmed that all sales for the raffle have been reconciled.'
        "
      >
        <i class="fa-solid fa-circle-info pl-2"></i>
      </span>
    </div>

    <!-- Close Raffle Modal -->
    <b-modal
      id="close-raffle-modal"
      title="Close Raffle"
      v-model="modalShow"
      @ok="handleOk"
      @close="reset"
      @cancel="reset"
      ok-variant="success"
      no-close-on-backdrop
      size="m"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <Alert :show="event.status === 'ended'" variant="yellow" icon="info">
        <p>Closing the raffle will finalize winner selection.</p>
      </Alert>
      <Alert v-if="errorMessage" variant="red" icon="exclamation">Failed to close the raffle: {{ errorMessage }}</Alert>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/LoadingButton';

import EventServiceV2 from '@/lib/event-service-v2';

import { mapGetters } from 'vuex';

export default {
  components: {
    Alert,
    Button
  },
  props: {
    event: {
      type: Object
    },
    winner: {
      type: Object
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      loading: false,
      canClose: true
    };
  },
  computed: {
    ...mapGetters(['disableCloseButton'])
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.errorMessage = null;
      this.onSubmit();
    },
    reset() {
      this.errorMessage = null;
    },
    async onSubmit() {
      try {
        this.loading = true;

        await EventServiceV2.closeEvent(this.event.id, this.winnerId);

        this.$router.go();

        this.modalShow = false;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.close-raffle-button {
  &__close {
    width: 90% !important;
    justify-content: center;
    font-weight: bold;
  }
}
</style>
