<template>
  <div>
    <b-alert v-if="error">{{ error }}</b-alert>
    <iframe :src="url" frameborder="0" :height="height" :width="width" allowtransparency></iframe>
  </div>
</template>
<script>
import ReportService from '@/lib/report-service';

export default {
  props: {
    eventId: {
      type: String,
      required: false
    },
    width: {
      type: String,
      default: '100%',
      required: false
    },
    height: {
      type: Number,
      default: 600,
      required: false
    },
    questionNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      url: null,
      error: null
    };
  },
  watch: {
    eventId: async function () {
      this.refreshReport();
    }
  },
  created() {
    this.refreshReport();
  },
  methods: {
    refreshReport: async function () {
      try {
        const params = {};
        if (this.eventId) {
          params.eventId = this.eventId;
        }
        const url = await ReportService.embedQuestion(this.questionNumber, params);
        this.url = url.url;
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.error = error.response.data.errors[0].message;
        } else {
          this.error = error;
        }
      }
    }
  }
};
</script>
<style scoped></style>
