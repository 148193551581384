<template>
  <div class="relative inline-block text-left">
    <div class="flex">
      <!-- Primary Action Button -->
      <button
        @click="primaryAction"
        :disabled="primaryActionDisabled"
        :class="{
          'pointer-events-none text-color-gray-100': primaryActionDisabled,
          'bg-gray-50 cursor-pointer': !primaryActionDisabled
        }"
        class="
          inline-flex
          justify-center
          w-full
          rounded-l-md
          border border-gray-300
          shadow-sm
          px-4
          py-2
          bg-white
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        <!-- Make this a slot -->
        <div v-if="$slots['primary-icon']" class="h-5 w-5 shrink-0">
          <slot name="primary-icon"></slot>
        </div>
        <span class="ml-2 grow">{{ primaryLabel }}</span>
      </button>
      <!-- Toggle Button for Dropdown -->
      <button
        @click="togglePopup"
        class="
          flex
          items-center
          justify-center
          w-12
          rounded-r-md
          border-l-0 border border-gray-300
          shadow-sm
          bg-white
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            v-if="!popupOpen"
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
          <path
            v-else
            fill-rule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <!-- Dropdown Menu -->
    <div
      v-if="popupOpen"
      class="
        origin-top-right
        absolute
        right-0
        mt-2
        min-w-36
        w-auto
        rounded-md
        shadow-lg
        bg-white
        ring-1 ring-black ring-opacity-5
        focus:outline-none
      "
    >
      <div class="py-1" role="menu" aria-orientation="vertical">
        <a
          v-for="(action, index) in secondaryActions"
          :key="action.label"
          @click="handleActionClick(action)"
          :class="{
            'pointer-events-none text-gray-400': action.disabled,
            'hover:bg-gray-50 cursor-pointer text-gray-700': !action.disabled
          }"
          class="flex items-center whitespace-nowrap px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
        >
          <div v-if="$slots['secondaryIcon' + index]" class="h-5 w-5 shrink-0">
            <slot :name="'secondaryIcon' + index"></slot>
          </div>
          <span class="ml-2 grow">{{ action.label }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitButton',
  props: {
    primaryLabel: {
      type: String,
      default: 'Primary Label'
    },
    primaryAction: {
      type: Function,
      required: true
    },
    primaryActionDisabled: {
      type: Boolean,
      default: false
    },
    secondaryActions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      popupOpen: false
    };
  },
  methods: {
    togglePopup() {
      this.popupOpen = !this.popupOpen;
    },
    handleActionClick(action) {
      if (!action.disabled) {
        action.method();
        this.popupOpen = false;
      }
    }
  }
};
</script>
